<template>
  <div>
    <main>
      <slot></slot>
    </main>
  </div>
</template>

<script>
export default {
  name: "BlankLayout",
  data: () => ({
    //
  }),
};
</script>

<style scoped>
</style>
