import Vue from 'vue'
import App from './App.vue'
import getRouter from './router'
import store from './store'
// import NProgress from 'vue-nprogress'
import apiService from './services/api.services';
import Layout from './layouts/Layout'
import BlankLayout from "./layouts/BlankLayout.vue";
import DashLayout from "./layouts/DashLayout.vue";
import PublicLayout from "./layouts/PublicLayout.vue";
import VueCountdown from '@chenfengyuan/vue-countdown';// Added by Vlad to enable countdown component
import VueI18n from 'vue-i18n';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.config.productionTip = false

// initialize api service, axio config
apiService.init();

// register layouts
Vue.component('layout', Layout)
Vue.component('BlankLayout', BlankLayout)
Vue.component('DashLayout', DashLayout)
Vue.component('PublicLayout', PublicLayout)
Vue.component(VueCountdown.name, VueCountdown);// Added by Vlad to register countdown component

import kr from "./locales/kr.js";
import en from "./locales/en.js"
import {CHANGELANG} from "@/store/action.types";
Vue.use(VueI18n);
const defaultLocale = localStorage.getItem('persistedLocale') ? JSON.parse(localStorage.getItem('persistedLocale')).locale.locale: 'en';
const i18n = new VueI18n({
  locale: defaultLocale,
  messages: { en, kr },
});
store.dispatch(CHANGELANG, i18n.locale).then( );

// register progress bar
// Vue.use(NProgress, {
//   latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
//   router: true, // Show progressbar when navigating routes, default: true
//   http: true, // Show progressbar when doing Vue.http, default: true
// })
// const nprogress = new NProgress()
// nprogress.configure({
//   showSpinner: false,
//   template:
//     '<div class="bar" style="height: 4px" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
// });

// import style
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/styles/main.css'

// vue2 datepicker
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
Vue.component('DatePicker', DatePicker)

// Global function for Google Translate widget initialization
window.googleTranslateElementInit = function () {
  new google.translate.TranslateElement({
    // pageLanguage: 'en',
    // includedLanguages: 'ko,zh-CN,zh-TW,ja,vi,th,tl,km,my,mn,ru,en,fr,ar',
    // layout: google.translate.TranslateElement.InlineLayout.Simple,
    // autoDisplay: true,
  }, 'google_translate_element');
}; 

// Use toLocaleString() to add thousand separators
Vue.filter('formatAmount', function (amount) {
  return amount.toLocaleString();
});


new Vue({
  // nprogress,
  router: getRouter(),
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
