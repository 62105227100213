import {ROLE} from "@/helpers/role";
import Store from "@/store";

export const hasObjectValue = (obj) => {
  let existCount = 0;
  for (const prop in obj) {
    if (obj[prop] != "" && obj[prop] != 0) existCount++;
  }
  return existCount > 0;
}

export const clearObjectValue = (obj) => {
  for (const prop in obj) {
    obj[prop] = typeof obj[prop] === "string" ? "" : 0;
  }
  return obj;
}

export const isBuyer = () => {
  return (
      Store.getters.authUser.role.roleName === ROLE.BuyerAdmin ||
      Store.getters.authUser.role.roleName === ROLE.BuyerUserAdmin ||
      Store.getters.authUser.role.roleName === ROLE.BuyerGeneralUser
  );
}