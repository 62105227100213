import apiService from "./api.services";

const offerService = {
  register(data) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return apiService.post('/auth/offer-register', data, config)
  },

  fetchOffers(searchParams, page, size, sortedBy, userId) {
    if (!page) page = 0
    else page -= 1

    if (!size) size = 50

    let params = { page, size, sort: sortedBy }

    for (const [key, value] of Object.entries(searchParams)) if (value && key !== 'page' && key !== 'size') params[key] = value

    return apiService.get('/auth/offers/' + userId, params);
  },

  deleteOffer(selectedItems) {
    const ids = selectedItems.map(item => item.id);
    return apiService.update('/auth/offer-delete', ids);
  },

  postOfferToAll(offerId) {
    return apiService.update(`/auth/offers/${offerId}/post-to-all`);
  },

  update(data) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return apiService.post('/auth/offer-update', data, config)
  },

  getDetail(offerId, cartId) {
    let params = { offerId, cartId }
    return apiService.get('/auth/offer-detail', params)
  },

  registerProduct(data) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return apiService.post('/auth/product-register', data, config)
  },

  editProduct(data) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return apiService.post('/auth/product-edit', data, config)
  },

  getOfferBoard(searchParams, page, size, sortedBy, offerType) {
    if (!page) page = 0
    else page -= 1

    if (!size) size = 50

    let params = { page, size, sort: sortedBy }

    for (const [key, value] of Object.entries(searchParams)) if (value && key !== 'page' && key !== 'size') params[key] = value

    return apiService.get('/auth/offer-board/' + offerType, params);
  },

  addToCart(offerId, userId) {
    let params = { offerId, userId }
    return apiService.get('/auth/offer-addtocart', params)
  },

  getOfferUserStatus(page, size, sortedBy, offerId) {
    if (!page) page = 0
    else page -= 1

    if (!size) size = 50

    let params = { page, size, sort: sortedBy }

    return apiService.get('/auth/offer-user-status/' + offerId, params);
  },

  getOfferCarts(searchParams, page, size, sortedBy) {

    if (!page) page = 0
    else page -= 1

    if (!size) size = 50

    let params = { page, size, sort: sortedBy }

    for (const [key, value] of Object.entries(searchParams)) {
      if (value && key != 'page' && key != 'size') params[key] = value
    }
    return apiService.get('/auth/offer-cart-list', params)
  },

  getOfferDashboard( page, size, sortedBy, userId) {
    if (!page) page = 0
    else page -= 1

    if (!size) size = 50

    let params = { page, size, sort: sortedBy }

    return apiService.get('/auth/offer-dashboard/' + userId, params);
  },

}

export default offerService;