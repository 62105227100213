export const camelCaseToSpaces = string => {
  // e.g. param('AbCd') =>  return 'Ab Cd'
  return string.replace(/([A-Z])/g, " $1")
};

export const stringHumanize = string => {
  // e.g. param('AB_CD') => 'ab_cd' => 'ab cd' =>  return 'Ab Cd'
  return string.toLowerCase().replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (matchKey) {
    return matchKey.toUpperCase();
  })
};
