var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout", { attrs: { template: _vm.$MixinLayout } }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", { staticClass: "text-center h1-txt" }, [_vm._v("Buyer Search")]),
      _c("section", { staticClass: "tab-container" }, [
        _c(
          "div",
          { staticClass: "tab-list" },
          _vm._l(_vm.categoryList, function (category, idx) {
            return _c(
              "a",
              {
                key: idx,
                class: {
                  "tab-button active": idx == _vm.selectedIdx,
                  "tab-button": idx != _vm.selectedIdx,
                },
                attrs: { href: "#", role: "button" },
                on: {
                  click: function ($event) {
                    return _vm.findByCategory(category.name, idx)
                  },
                },
              },
              [_vm._v(_vm._s(category.name))]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "tab-content active",
            staticStyle: { display: "block", "margin-top": "30px" },
            attrs: { id: "category.id" },
          },
          [
            _c("h2", { staticClass: "offscreen" }, [
              _vm._v("Mechanical Parts"),
            ]),
            _c(
              "div",
              { staticClass: "items" },
              [
                _vm.loading
                  ? _c("div", { staticClass: "col-md-12 spinner-container" }, [
                      _c("div", { staticClass: "spinner" }),
                    ])
                  : _vm._e(),
                !_vm.loading && _vm.buyers.length < 1
                  ? _c("div", [_vm._v(" No Data ")])
                  : _vm._e(),
                _vm._l(_vm.buyers, function (buyer, idx) {
                  return _c("div", { key: idx, staticClass: "item" }, [
                    _c("div", { staticClass: "com-tit" }, [
                      _c("div", { staticClass: "left" }, [
                        buyer.attachment && buyer.attachment.attachmentUrl
                          ? _c("img", {
                              attrs: { src: buyer.attachment.attachmentUrl },
                            })
                          : _vm._e(),
                        !buyer.attachment
                          ? _c("img", {
                              attrs: {
                                src: require("../assets/images/logo.png"),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c("strong", [_vm._v(_vm._s(buyer.companyName))]),
                        _c("span", [_vm._v(_vm._s(buyer.companyName))]),
                      ]),
                    ]),
                    _c("ul", { staticClass: "com-content" }, [
                      _c("li", { staticClass: "category" }, [
                        _c("strong", [_vm._v("Category")]),
                        _c("span", [
                          _c("em", [_vm._v(_vm._s(buyer.categories))]),
                        ]),
                      ]),
                      _c("li", [
                        _c("strong", [_vm._v("ADDR")]),
                        _c("span", [_vm._v(_vm._s(buyer.address))]),
                      ]),
                      _c("li", [
                        _c("strong", [_vm._v("TEL")]),
                        _c("span", [_vm._v(_vm._s(buyer.contactPersonPhNo))]),
                      ]),
                      _c("li", [
                        _c("strong", [_vm._v("HOME PAGE")]),
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: { href: buyer.homePage },
                          },
                          [_vm._v(_vm._s(buyer.homePage))]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary buttonload mt-2 font-15",
                            attrs: { type: "submit" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.proposal(buyer.id)
                              },
                            },
                          },
                          [_vm._v("Proposal")]
                        ),
                      ]),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ]
        ),
        _c("div", { staticClass: "tab-content", attrs: { id: "tab2" } }, [
          _c("h2", { staticClass: "offscreen" }, [_vm._v("소재/부품/장비")]),
          _c("div", { staticClass: "items" }, [_vm._v("소재/부품/장비")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }