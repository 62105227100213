export const OFFER_STATUS = [
    { label: 'Draft', value: 'Draft' },
    { label: 'Posting', value: 'Posting' },
    { label: 'Added', value: 'Added' },
    { label: 'Unread', value: 'Unread' },
    { label: 'Read', value: 'Read' },
    { label: 'Nego', value: 'Nego' },
    { label: 'QuotationRequest', value: 'Q_R' },
    { label: 'Reject', value: 'Reject' },
    { label: 'Contract', value: 'Contract' },
    { label: 'Close', value: 'Close' },
]
