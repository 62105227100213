import apiService from "./api.services";

export default {
  getList(searchParams, page, size, sortedBy) {

    if (!page) page = 0
    else page -= 1

    if (!size) size = 50

    let params = {
      page,
      size,
      sort: sortedBy
    }

    for (const [key, value] of Object.entries(searchParams)) {
      if (value && key != 'page' && key != 'size') params[key] = value
    }

    return apiService.get('/auth/category', params)
  },

  getAll(status) {
    let param = { status }
    let config = {
      headers: {
        'Content-Type': 'text/plain'
      }
    }
    return apiService.get('/all-category', param, config)
  },

  getById(id) {
    return apiService.get('/auth/category/' + id)
  },

  save(data) {
    if (data.id) return apiService.update('/auth/category', data)
    else return apiService.post('/auth/category', data)
  },
}
