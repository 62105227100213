import apiService from "./api.services";

export default {
  getList(searchParams, page, size, sortedBy) {

    if (!page) page = 0
    else page -= 1

    if (!size) size = 50

    let params = {
      page,
      size,
      sort: sortedBy
    }

    for (const [key, value] of Object.entries(searchParams)) {
      if (value && key != 'page' && key != 'size') params[key] = value
    }

    return apiService.get('/auth/buyer-user', params)
  },

  getById(id) {
    return apiService.get('/auth/buyer-user/' + id)
  },

  save(data) {
    if (data.id) return apiService.update('/auth/buyer-user', data)
    else return apiService.post('/auth/buyer-user', data)
  },

  delete(id) {
    return apiService.delete('/auth/delete-buyer-user/' + id)
  },

  findByCategory(category) {
    let config = {
      headers: {
        'Content-Type': 'text/plain'
      }
    }
    return apiService.get('/auth/buyer-by-category/' , {category}, config);
  },
}
