<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light public-nav header-top">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img src="../assets/images/only-logo.png" alt="GSP" class="logo-img"/>Global Sourcing Platform
      </router-link>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse navbar-right" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link font-weight-bold" to="/signin">
              Sign In
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link font-weight-bold" to="/sign-up">
              Sign Up
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link font-weight-bold" href="#">About BigBuyer</a>
          </li>
          <li class="nav-item">
            <a class="nav-link font-weight-bold" href="#">Buyer List</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="#">
              <GoogleTranslateWidget />
            </a>
          </li>
          <!-- <li class="nav-item" style="width: 100px;"></li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

import GoogleTranslateWidget from "@/components/public/GoogleTranslateWidget.vue";
export default {
  components: {
    GoogleTranslateWidget
  },
};
</script>

<style>
.logo-img{
  width: auto;
  height: auto;
}
</style>
