<template>
  <div>
    <header>
      <dash-header-nav></dash-header-nav>
    </header>
    <main>
      <slot></slot>
    </main>
    <public-footer></public-footer>
  </div>
</template>

<script>
import DashHeaderNav from "../components/DashHeaderNav.vue";
import PublicFooter from "../components/Footer.vue";

export default {
  name: "DashLayout",
  components: { DashHeaderNav, PublicFooter },
  data: () => ({
    //
  }),
};
</script>

<style scoped>
</style>
