export default {
  hello: '안녕하세요',

  // Buyer Sign up
  generalInfo: "일반 정보",
  buyerSignUp : "구매자 가입",
  userId : "사용자 아이디",
  representativeName : "대표자명",
  password: "비밀번호",
  contactPerson: "담당자",
  companyName: "회사 이름",
  contactPersonPhNo: "담당자 전화번호",
  companyRegNo: "사업자등록번호",
  contactEmail: "이메일 연락처",
  callNo: "전화 번호",
  acceptTermsConditions :  "이용약관 동의",
  join: "가입하다",

  //footer
  gsp : "글로벌 소싱 플랫폼",
  address : "이씨플라자 | 주소: 서울특별시 강동구 강동대로 143-40, 1층 | 대표이사: 박인규 | 개인정보관리책임자: 이동하 | 사업자등록번호 : 212-81-46495 | 온라인마케팅사업신고번호: 833",
  copyright : "Copyright © 2023 (주)이씨플라자네트워크 All rights reserved.",
  
  carouselDesc: "ECPlaza 는 글로벌 소싱 플랫폼 개발을 통한 협력 서비스를 제공합니다.글로벌 소싱 플랫폼은 슈퍼 바이어와 국내외 기업 간의 연결을 도울 것입니다.",

  // Sign up page
   signUp : "가입하기",
   supplierSignUp : "공급업체 가입",
   changeRegMethod: "등록 방법 변경",
   usingModernReg: "최신 등록 사용",
   usingGeneralReg: "일반 등록 사용",
   welcomeText: "우리와 함께 오신 것을 환영합니다!",
   thankText: "글로벌 소싱 플랫폼 서비스 및 제품(이하 '서비스')을 이용해 주셔서 감사합니다. 이 약관은 각종 소싱플랫폼 서비스의 이용과 관련하여 서비스를 제공하는 ㈜이씨플라자(이하 '이씨플라자')와 소싱플랫폼 서비스 회원(이하 '이씨플라자'라 합니다. '회원') 또는 비회원이 이를 이용하는 경우. 소싱플랫폼 서비스 이용에 도움이 되는 유용한 정보를 담고 있습니다. ", 
   thankText2: "소싱플랫폼 서비스를 이용하시거나 소싱플랫폼 서비스 회원으로 가입하시면 본 약관 및 관련 운영정책을 확인 또는 동의하시게 되니 잠시 시간을 내어 꼼꼼히 검토해 주시기 바랍니다.",
   continue: "계속하다",
   modernReg: "현대 등록",
   generalReg: "일반 등록",

   //supplier sign up
  mainTradingItem: "주요 거래 품목 ",
  faxNo:  "팩스 번호 ",
  category: "범주",
  nation: "국가",
  companyType: "회사 유형",
  homePageUrl: "홈페이지 URL",
  supplierAddress: "주소",
  representativePhNo: "대표전화번호",

  //buyer user
  buyerUser: "구매자 사용자",
  register: "등록하다",
  name: "이름",
  department: "부서",
  position: "위치",
  officeNo: "사무실 번호",
  mobileNo: "휴대폰 번호",
  registrationDate: "등록 날짜",
  action: "행동",
  edit: "편집하다",
  search: "찾다",
  clear: "분명한",

  //menu
  menu: "메뉴",
  supplierEntry: "공급업체 항목",
  purchaseProject: "구매 프로젝트",
  reqForQuotation: "견적 요청",
  quotation: "인용",
  quotationHistory: "견적내역",
  purchaseOrder: "그매 주문서",
  purchaseOrderHistory: "그매 주문 내역",
  deliInspection: "납품 검사",
  deliInspectionHistory: "납품 검사 내역",
  usance: "유상스",
  changePassword: "비밀번호 변경",
  logout: "로그 아웃",


};