import apiService from '../../services/api.services';
import jwtService from '../../services/jwt.services';
import { GET, FORCEAUTH } from '../action.types';
import { SET_QRCODE, UPDATE_QRCODE } from '../mutations.types';
import { AUTH_START_ROUTE } from "../../helpers/authStartRoute";
// import store from "../../store";

// this module is added by Vlad to provide qrcode related logic for new authentication
const state = { qrcode: { qlink: null, image:{}, message:"", period: 600000, toget:"", ssid:"", role:null, tocheck:""}, interval:3000 };
const getters = { QRCode: state => state.qrcode, QRCodeInterval: state => state.interval };

const actions = 
{
  [GET]( { commit }, data)
  {
    data.message.error = false; data.message.show = false; state.qrcode.error = false;
    return new Promise((resolve, reject) => 
    {
        let param = {SSID:state.qrcode.ssid, ROLE:state.qrcode.role};
        // console.log("param: ", param);
        if (data.qrcode.order !== undefined) param.ORDER = data.qrcode.order;
        apiService.post(data.qrcode.toget, param).then(feedback =>
        {
          switch (feedback.status)
          {
            case 200: break;
            case 204: return resolve({}); // there is no login result yet
            case 203: // refresh QR Code as login one
            { 
              // let result = JSON.parse(feedback.data);
              // 
                console.log("feedback: ", feedback);
                commit(UPDATE_QRCODE, {toget: "/modern_login"});
                return reject(feedback.data);
            } 
            default: reject("Unsupported HTTP result from the server:: " + feedback.status);
          }
          let result = JSON.parse(feedback.data);

          switch (result.status)
          {
            case "NONBINDED":
            {
                commit(UPDATE_QRCODE, {role: result.ROLE});
                let token = JSON.parse(result.TOKEN);
                jwtService.saveAuthUser(token.user);
                jwtService.saveToken(token.token);  
                return resolve({mode:false, message:result.message});
            }
            case "ERROR": { return reject(result.message); }
            case "CONFIRMED": return resolve({mode:true, data: JSON.parse(result.message)});
            case "ACTIVE":
            {
              const token = JSON.parse(result.TOKEN);
              this.dispatch(FORCEAUTH, token).then(() => 
              {
                  let routeName = "auth-forbidden";
                  if (AUTH_START_ROUTE[token.user.role.roleName]) routeName = AUTH_START_ROUTE[token.user.role.roleName];
                  // commit(CLEAN_QRCODE);
                  resolve({mode:true, router:{ name: routeName, params: { username: token.user.username } }});
              }).catch(() => {}); 
              return {};            
            }
            default: if (result.QLINK === undefined) return reject("Unsupported status: " + result.status); else break;
          }
          if (result.QLINK === "qrl://0.0.0.0/.sqrlauth?nut=ERROR")
          {
            result.message = "";
            result.error = true;
            commit(SET_QRCODE, result);
            reject("Unable connect to server");
          }
          else
          {
            if (state.qrcode.role) result.ROLE = state.qrcode.role;
            result.toget = feedback.config.url;
            result.tocheck = data.qrcode.tocheck;
            result.message = data.qrcode.message;
            result.error = false;
            commit(SET_QRCODE, result);
            resolve();
          }
        })
        .catch ((error) =>
        {
          console.log(error)
          let result = {};
          result.message = "";
          result.error = true;
          result.SSID = state.qrcode.ssid;
          result.toget = data.qrcode.toget;
          result.ROLE = state.qrcode.role;

          commit(SET_QRCODE, result);
          if (error.response !== undefined && error.response.data !== undefined && typeof error.response.data === "object" && error.response.data.error === true) reject(error.response.data.message);
          reject(error.message);
        });
    });
  }
};

const mutations = 
{
    [SET_QRCODE](state, data) 
    {
      state.qrcode.qlink = data.QLINK;
      state.qrcode.image = data.QRCODE;
      state.qrcode.role = data.ROLE === undefined ? null : data.ROLE;
      state.qrcode.ssid = data.SSID;
      state.qrcode.error = data.error;
      state.qrcode.toget = data.toget;
      state.qrcode.tocheck = data.tocheck;
      state.qrcode.loginSuccess = true;
      state.qrcode.message = data.message;
    },
    [UPDATE_QRCODE](state, value) 
    {
      if (value.toget !== undefined) state.qrcode.toget = value.toget
      if (value.role !== undefined) state.qrcode.role = value.role
}
    // [CLEAN_QRCODE](state) { console.log("CLEANNED: " + state.qrcode.role);} //state.qrcode = {};
};

export default { state, actions, mutations, getters };
