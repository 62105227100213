export default (authUser) => ([
  {
    header: true,
    label: 'Menu'
  },

  {
    icon: 'bi bi-person-fill',
    label: 'User List',
    to: {
      name: 'auth-user-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-diagram-2-fill',
    label: 'Company Type',
    to: {
      name: 'auth-company-type-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-collection-fill',
    label: 'Category',
    to: {
      name: 'auth-category-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-map-fill',
    label: 'Nation',
    to: {
      name: 'auth-nation-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-text-fill',
    label: 'Buyer Sign Up',
    to: {
      name: 'auth-buyer-sign-up-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-text-fill',
    label: 'Supplier Sign Up',
    to: {
      name: 'auth-supplier-sign-up-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-person-lines-fill',
    label: 'Buyer User',
    to: {
      name: 'auth-buyer-user-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-person-lines-fill',
    label: 'Supplier User',
    to: {
      name: 'auth-supplier-user-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Supplier Entry',
    to: {
      name: 'auth-supplier-entry-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Purchase Project',
    to: {
      name: 'auth-purchase-project-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Quotation Request',
    to: {
      name: 'auth-quotation-request-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Quotation',
    to: {
      name: 'auth-quotation-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Quotation History',
    to: {
      name: 'auth-quotation-history-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Purchase Order',
    to: {
      name: 'auth-purchase-order-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Purchase Order History',
    to: {
      name: 'auth-purchase-order-history-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Delivery Inspection',
    to: {
      name: 'auth-delivery-inspection-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Delivery Inspection History',
    to: {
      name: 'auth-delivery-inspection-history-list',
      params: { username: authUser.username }
    }
  },
])
