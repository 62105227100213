<template>
  <footer>
    <div class="container">
      <h4>{{ $t('gsp') }}</h4>
      <p class="mt-2">
        {{ $t('address') }}
      </p>
      <p class="mt-1">
        {{ $t('copyright') }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
};
</script>

<style>
</style>
