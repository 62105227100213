export default (authUser) => ([
  {
    header: true,
    label: 'Menu'
  },
  {
    icon: 'bi bi-person-lines-fill',
    label: 'Supplier User',
    to: {
      name: 'auth-supplier-user-list',
      params: { username: authUser.username }
    }
  },
])
