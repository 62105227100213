/**
 * 'meta.component' must be the same with file name or
 * file name with path in 'src/view/' folder
 *
 * e.g. if 'Home.vue' is under 'src/view/', `meta.component` must be 'Home.vue'
 *
 *      Note: 'meta.component' is case sensitive.
 */
import {ROLE} from "../../helpers/role";

const routes =
    [
        {
            path: '*',
            redirect: '/notfound'
        },
        {
            // 404 page
            path: '/notfound',
            name: 'public-notfound',
            meta: {
                layout: "PublicLayout",
                component: 'NotFound.vue'
            }
        },
        {
            // No access
            path: '/forbidden',
            name: 'auth-forbidden',
            meta: {
                layout: "DashLayout",
                component: 'Forbidden.vue'
            }
        },
        {
            path: '/ecplaza-auth',
            name: 'auto-login',
            meta: {
                layout: "PublicLayout",
                component: 'AutoLogin.vue'
            }
        },
      
        {
            path: '/',
            name: 'public-home',
            meta: {
                layout: "HeaderLayout",
                component: 'Home.vue'
            }
        },
        {
            path: '/dash/:username/dashboard',
            name: 'auth-dashboard',
            meta: {
                layout: "DashLayout",
                component: 'Dashboard.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser
                ],
                meta: {
                    showProgressBar: false
                  }
            }
        },
        {
            path: '/dash/:username/buyer-search',
            name: 'auth-buyer-search',
            meta: {
                layout: "DashLayout",
                component: 'BuyerSearch.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierUserAdmin,
                    ROLE.SupplierGeneralUser
                ],
            }
        },
        {
            path: '/dash/:username/supplier-search',
            name: 'auth-supplier-search',
            meta: {
                layout: "DashLayout",
                component: 'SupplierSearch.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerUserAdmin,
                    ROLE.BuyerGeneralUser
                ],
            }
        },
        {
            path: '/sign-up',
            name: 'public-sign-up',
            meta: {
                layout: "PublicLayout",
                component: 'SignUp.vue'
            }
        },
        {
            path: '/buyer-sign-up',
            name: 'public-buyer-sign-up',
            meta: {layout: "PublicLayout", component: 'BuyerSignUp.vue'},
            props: (route) => ({modernRegistration: (String(route.query.way).toLowerCase() === 'true')}) //added by Vlad to provide parameters to a component
        },
        {
            path: '/supplier-sign-up',
            name: 'public-supplier-sign-up',
            meta: {layout: "PublicLayout", component: 'SupplierSignUp.vue'},
            props: (route) => ({modernRegistration: (String(route.query.way).toLowerCase() === 'true')}) //added by Vlad to provide parameters to a component
        },
        {
            path: '/signin',
            name: 'public-signin',
            meta: {layout: "HeaderLayout", component: 'Login.vue'},
            props: (route) => ({bindingRequired: (String(route.query.way).toLowerCase() === 'true')}) //added by Vlad to provide parameters to a component
        },
        // auth user list routes
        {
            path: '/dash/:username/users',
            name: 'auth-user-list',
            meta: {
                layout: "DashLayout",
                component: 'User/UserList.vue',
                authRequired: true,
                authorize: [ROLE.Admin]
            },
        },
        {
            path: '/dash/:username/users/detail/:itemId',
            name: 'auth-user-detail',
            meta: {
                layout: "DashLayout",
                component: 'User/UserDetail.vue',
                authRequired: true,
                authorize: [ROLE.Admin]
            },
        },
        {
            path: '/supplier-invitation',
            name: 'supplier-invitation',
            meta: {
                layout: "DashLayout",
                component: 'SupplierInvitation.vue',
                authRequired: true,
                authorize: [
                    ROLE.BuyerAdmin,
                ]
            }
        },
        {
            path: '/dash/:username/change-password',
            name: 'auth-user-change-password',
            meta: {
                layout: "DashLayout",
                component: 'ChangePassword.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.SupplierAdmin,
                    ROLE.BuyerUserAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierUserAdmin,
                    ROLE.SupplierGeneralUser
                ]
            },
        },
        // buyer sign up
        {
            path: '/dash/:username/buyer-sign-up',
            name: 'auth-buyer-sign-up-list',
            meta: {
                layout: "DashLayout",
                component: 'BuyerSignUp/BuyerSignUpList.vue',
                authRequired: true,
                authorize: [ROLE.Admin]
            },
        },
        {
            path: '/dash/:username/buyer-sign-up/detail/:itemId',
            name: 'auth-buyer-sign-up-detail',
            meta: {
                layout: "DashLayout",
                component: 'BuyerSignUp/BuyerSignUpDetail.vue',
                authRequired: true,
                authorize: [ROLE.Admin]
            },
        },
        // supplier sign up
        {
            path: '/dash/:username/supplier-sign-up',
            name: 'auth-supplier-sign-up-list',
            meta: {
                layout: "DashLayout",
                component: 'SupplierSignUp/SupplierSignUpList.vue',
                authRequired: true,
                authorize: [ROLE.Admin]
            },
        },
        {
            path: '/dash/:username/supplier-sign-up/detail/:itemId',
            name: 'auth-supplier-sign-up-detail',
            meta: {
                layout: "DashLayout",
                component: 'SupplierSignUp/SupplierSignUpDetail.vue',
                authRequired: true,
                authorize: [ROLE.Admin]
            },
        },
        // buyer user
        {
            path: '/dash/:username/buyer-user',
            name: 'auth-buyer-user-list',
            meta: {
                layout: "DashLayout",
                component: 'BuyerUser/BuyerUserList.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.BuyerAdmin, ROLE.BuyerUserAdmin]
            },
        },
        {
            path: '/dash/:username/buyer-user/detail/:itemId',
            name: 'auth-buyer-user-detail',
            meta: {
                layout: "DashLayout",
                component: 'BuyerUser/BuyerUserDetail.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.BuyerAdmin, ROLE.BuyerUserAdmin]
            },
        },
        // supplier user
        {
            path: '/dash/:username/supplier-user',
            name: 'auth-supplier-user-list',
            meta: {
                layout: "DashLayout",
                component: 'SupplierUser/SupplierUserList.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.SupplierAdmin, ROLE.SupplierUserAdmin]
            },
        },
        {
            path: '/dash/:username/supplier-user/detail/:itemId',
            name: 'auth-supplier-user-detail',
            meta: {
                layout: "DashLayout",
                component: 'SupplierUser/SupplierUserDetail.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.SupplierAdmin, ROLE.SupplierUserAdmin]
            },
        },
        {
            path: '/dashboard',
            name: 'auth-dashboard',
            meta: {
                layout: "DashLayout",
                component: 'Dashboard.vue'
            }
        },
        // supplier entry
        {
            path: '/dash/:username/supplier-entry',
            name: 'auth-supplier-entry-list',
            meta: {
                layout: "DashLayout",
                component: 'SupplierEntry/SupplierEntryList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        {
            path: '/dash/:username/supplier-entry/detail/:itemId',
            name: 'auth-supplier-entry-detail',
            meta: {
                layout: "DashLayout",
                component: 'SupplierEntry/SupplierEntryDetail.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        // company type
        {
            path: '/dash/:username/company-type',
            name: 'auth-company-type-list',
            meta: {
                layout: "DashLayout",
                component: 'CompanyType/CompanyTypeList.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.BuyerAdmin]
            },
        },
        {
            path: '/dash/:username/company-type/detail/:itemId',
            name: 'auth-company-type-detail',
            meta: {
                layout: "DashLayout",
                component: 'CompanyType/CompanyTypeDetail.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.BuyerAdmin]
            },
        },
        // category
        {
            path: '/dash/:username/category',
            name: 'auth-category-list',
            meta: {
                layout: "DashLayout",
                component: 'Category/CategoryList.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.BuyerAdmin]
            },
        },
        {
            path: '/dash/:username/category/detail/:itemId',
            name: 'auth-category-detail',
            meta: {
                layout: "DashLayout",
                component: 'Category/CategoryDetail.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.BuyerAdmin]
            },
        },
        // nation
        {
            path: '/dash/:username/nation',
            name: 'auth-nation-list',
            meta: {
                layout: "DashLayout",
                component: 'Nation/NationList.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.BuyerAdmin]
            },
        },
        {
            path: '/dash/:username/nation/detail/:itemId',
            name: 'auth-nation-detail',
            meta: {
                layout: "DashLayout",
                component: 'Nation/NationDetail.vue',
                authRequired: true,
                authorize: [ROLE.Admin, ROLE.BuyerAdmin]
            },
        },
        // supplier profile
        {
            path: '/dash/:username/supplier-profile',
            name: 'auth-supplier-user-profile',
            meta: {
                layout: "DashLayout",
                component: 'UserProfile/SupplierUserProfile.vue',
                authRequired: true,
                authorize: [ROLE.SupplierUserAdmin, ROLE.SupplierGeneralUser]
            },
        },
        // buyer profile
        {
            path: '/dash/:username/buyer-profile',
            name: 'auth-buyer-user-profile',
            meta: {
                layout: "DashLayout",
                component: 'UserProfile/BuyerUserProfile.vue',
                authRequired: true,
                authorize: [ROLE.BuyerUserAdmin, ROLE.BuyerGeneralUser]
            },
        },
        // buyer admin profile
        {
            path: '/dash/:username/buyer-admin-profile',
            name: 'auth-buyer-admin-profile',
            meta: {
                layout: "DashLayout",
                component: 'UserProfile/BuyerAdminProfile.vue',
                authRequired: true,
                authorize: [ROLE.BuyerAdmin]
            },
        },
        // supplier admin profile
        {
            path: '/dash/:username/supplier-admin-profile',
            name: 'auth-supplier-admin-profile',
            meta: {
                layout: "DashLayout",
                component: 'UserProfile/SupplierAdminProfile.vue',
                authRequired: true,
                authorize: [ROLE.SupplierAdmin]
            },
        },
        //offer
        {
            path: '/dash/:username/offer-list',
            name: 'offer-list',
            meta: {
                layout: "DashLayout",
                component: 'Offer/OfferList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser
                ]
            },
        },
        {
            path: '/dash/:username/offer-detail/:offerId/:cartId/:action/:buyerId',
            name: 'offer-detail',
            meta: {
                layout: "DashLayout",
                component: 'Offer/OfferDetail.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser
                ]
            },
        },
        {
            path: '/dash/:username/offer-board',
            name: 'offer-board',
            meta: {
                layout: "DashLayout",
                component: 'Offer/OfferBoard.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser
                ]
            },
        },
        {
            path: '/dash/:username/offer-cart',
            name: 'offer-cart',
            meta: {
                layout: "DashLayout",
                component: 'Offer/OfferCart.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser
                ]
            },
        },
        {
            path: '/dash/:username/offer-user-status-list/:itemId',
            name: 'offer-user-status-list',
            meta: {
                layout: "DashLayout",
                component: 'Offer/OfferUserStatusList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser
                ]
            },
        },

        {
            path: '/dash/:username/nego/:offerCartId/:company/:receiverId',
            name: 'nego',
            meta: {
                layout: "DashLayout",
                component: 'Offer/Nego.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser
                ]
            },
        },
        // purchase project
        {
            path: '/dash/:username/purchase-project',
            name: 'auth-purchase-project-list',
            meta: {
                layout: "DashLayout",
                component: 'PurchaseProject/PurchaseProjectList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                ]
            },
        },
        // auth-KYB-list
        {
            path: '/dash/:username/KYB-request',
            name: 'auth-KYB-list',
            meta:
                {
                    layout: "DashLayout",
                    component: 'KYBRequest/KYBRequestList.vue',
                    authRequired: true,
                    authorize:
                        [
                            ROLE.BuyerAdmin,
                        ]
                },
        },
        // auth-USANCE-list
        {
            path: '/dash/:username/USANCE-request',
            name: 'auth-USANCE-list',
            meta:
                {
                    layout: "DashLayout",
                    component: 'USANCERequest/USANCERequestList.vue',
                    authRequired: true,
                    authorize:
                        [
                            ROLE.BuyerAdmin,
                        ]
                },
        },
        // auth-MKD-list
        {
            path: '/dash/:username/MKD-request',
            name: 'auth-MKD-list',
            meta:
                {
                    layout: "DashLayout",
                    component: 'MKDRequest/MKDRequestList.vue',
                    authRequired: true,
                    authorize:
                        [
                            ROLE.SupplierAdmin,
                        ]
                },
        },
        {
            path: '/dash/:username/purchase-project/detail/:itemId',
            name: 'auth-purchase-project-detail',
            meta: {
                layout: "DashLayout",
                component: 'PurchaseProject/PurchaseProjectDetail.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                ]
            },
        },
        // quotation Request
        {
            path: '/dash/:username/quotation-request',
            name: 'auth-quotation-request-list',
            meta: {
                layout: "DashLayout",
                component: 'QuotationRequest/QuotationRequestList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser
                ]
            },
        },
        {
            path: '/dash/:username/quotation-request/detail/:itemId',
            name: 'auth-quotation-request-detail',
            meta: {
                layout: "DashLayout",
                component: 'QuotationRequest/QuotationRequestDetail.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser
                ]
            },
        },
        // quotation
        {
            path: '/dash/:username/quotation',
            name: 'auth-quotation-list',
            meta: {
                layout: "DashLayout",
                component: 'Quotation/QuotationList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,]
            },
        },
        {
            path: '/dash/:username/quotation/detail/:itemId',
            name: 'auth-quotation-detail',
            meta: {
                layout: "DashLayout",
                component: 'Quotation/QuotationDetail.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        {
            path: '/dash/:username/quotation-history',
            name: 'auth-quotation-history-list',
            meta: {
                layout: "DashLayout",
                component: 'QuotationHistory/QuotationHistoryList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        // purchase-order
        {
            path: '/dash/:username/purchase-order',
            name: 'auth-purchase-order-list',
            meta: {
                layout: "DashLayout",
                component: 'PurchaseOrder/PurchaseOrderList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        {
            path: '/dash/:username/purchase-order/detail/:itemId',
            name: 'auth-purchase-order-detail',
            meta: {
                layout: "DashLayout",
                component: 'PurchaseOrder/PurchaseOrderDetail.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        {
            path: '/dash/:username/purchase-order-history',
            name: 'auth-purchase-order-history-list',
            meta: {
                layout: "DashLayout",
                component: 'PurchaseOrderHistory/PurchaseOrderHistoryList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        // delivery inspection
        {
            path: '/dash/:username/delivery-inspection',
            name: 'auth-delivery-inspection-list',
            meta: {
                layout: "DashLayout",
                component: 'DeliveryInspection/DeliveryInspectionList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        {
            path: '/dash/:username/delivery-inspection/detail/:itemId',
            name: 'auth-delivery-inspection-detail',
            meta: {
                layout: "DashLayout",
                component: 'DeliveryInspection/DeliveryInspectionDetail.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        {
            path: '/dash/:username/delivery-inspection-history',
            name: 'auth-delivery-inspection-history-list',
            meta: {
                layout: "DashLayout",
                component: 'DeliveryInspectionHistory/DeliveryInspectionHistoryList.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                    ROLE.SupplierAdmin,
                    ROLE.SupplierGeneralUser,
                ]
            },
        },
        {  //added by Vlad to allow KYB protocol
            name: 'KYB',
            path: '/KYB',
            meta: {},
            beforeEnter(to, _from, next) {
                window.location.href = 'https://localhost:4200/hanakyb?nut=' + to.params.value + "&pek=" + to.params.value2;
                next();
            }
        },
        // {  //added by Vlad to allow Usance protocol
        //   name: 'USANCE',
        //   path: '/USANCE',
        //   meta: {},
        //   beforeEnter(to, _from, next)
        //   {
        //     window.location.href = to.params.value;
        //     next();
        //   }
        // },
        {  //added by Vlad to allow GPL protocol
            name: 'GPL',
            path: '/GPL',
            meta: {},
            beforeEnter(to, _from, next) {
                window.location.href = 'https://localhost:4200/hanagpl?nut=' + to.params.value + "&pek=" + to.params.value2;
                next();
            }
        },
        {
            path: '/dash/:username/usance-project/add',
            name: 'auth-usance-project-add',
            meta: {
                layout: "DashLayout",
                component: 'USANCERequest/USANCEProjectAdd.vue',
                authRequired: true,
                authorize: [
                    ROLE.Admin,
                    ROLE.BuyerAdmin,
                    ROLE.BuyerGeneralUser,
                ]
            },
        },
        {
            path: '/usanceCallback',
            name: 'usanceCallback',
            meta: {component: 'ModalRoute.vue', layout: "Layout",},
        },
        {
            path: '/test',
            name: 'Test',
            meta: {component: 'Test.vue', layout: "Layout",},
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            meta: {
                layout: "PublicLayout",
                component: 'ForgotPassword.vue'
            }
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            meta: {
                layout: "PublicLayout",
                component: 'ResetPassword.vue'
            }
        },
       

    ];

export default routes.map(route => {
    return {
        ...route, component: () => import
            (
            /* webpackChunkName: "noprefetch-[request]" */
            `../../views/${route.meta.component}`
            )
    }
})
