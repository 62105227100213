
<script src="https://code.jquery.com/jquery-3.6.0.min.js">
</script>
<template>
  <layout :template="$MixinLayout">
    <div class="container">
      <h1 class="text-center  h1-txt">Buyer Search</h1>
      <section class="tab-container">
        <div class="tab-list">
          <a href="#" v-for="(category, idx) in categoryList" :key="idx"
            v-bind:class="{ 'tab-button active': idx == selectedIdx, 'tab-button': idx != selectedIdx }" role="button"
            @click="findByCategory(category.name, idx)">{{ category.name }}</a>
        </div>

        <div class="tab-content active" id="category.id" style="display: block; margin-top: 30px; ">
          <h2 class="offscreen">Mechanical Parts</h2>
          <div class="items">
            <!-- loading wrapper -->
            <div v-if="loading" class="col-md-12 spinner-container">
              <div class="spinner"></div>
            </div>
            <!-- -->
            <div v-if="!loading && buyers.length < 1">
              No Data
            </div>
            <div class="item" v-for="(buyer, idx) in buyers" :key="idx">
              <div class="com-tit">
                <div class="left">
                  <img v-if="buyer.attachment && buyer.attachment.attachmentUrl" :src="buyer.attachment.attachmentUrl">
                  <img v-if="!buyer.attachment" src="../assets/images/logo.png">
                </div>
                <div class="right">
                  <strong>{{ buyer.companyName }}</strong>
                  <span>{{ buyer.companyName }}</span>
                </div>
              </div>
              <ul class="com-content">
                <li class="category">
                  <strong>Category</strong>
                  <span><em>{{ buyer.categories }}</em></span>
                </li>
                <li>
                  <strong>ADDR</strong>
                  <span>{{ buyer.address }}</span>
                </li>
                <li>
                  <strong>TEL</strong>
                  <span>{{ buyer.contactPersonPhNo }}</span>
                </li>
                <li>
                  <strong>HOME PAGE</strong>
                  <a :href="buyer.homePage" class="link">{{ buyer.homePage }}</a>
                </li>
                <li> <button type="submit" class="btn btn-primary buttonload mt-2 font-15"
                    @click.prevent="proposal(buyer.id)">Proposal</button></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="tab-content" id="tab2">
          <h2 class="offscreen">소재/부품/장비</h2>
          <div class="items">소재/부품/장비</div>
        </div>
      </section>
    </div>
  </layout>
</template>

  <script>
  import layoutMixin from "../mixins/layout.mixins";
  import categoryServices from "../services/category.services";
  import buyerUserServices from "../services/buyerUser.services";
  export default {
    name: "auth-buyer-search",
    mixins: [layoutMixin],
    components:
    {
    },

    data: () => ({
     categoryList: [],
     buyers: [],
     selectedIdx: 0,
     loading: false,
    }),
    created() {
     this.getCategories();
     this.findByCategory("Material Parts",0)
    },
    computed: {
    authUser() {
      return this.$store.getters.authUser;
    }
    },
  
    methods:
    {

      proposal(buyerId){
      let cartId = 0;
      let offerId = 0;
      let action = "new";
      this.$router.push({
        name: "offer-detail",
        params: { username: this.authUser.username, offerId, cartId,action, buyerId},
      });
      },

      async getCategories() {
        try {
          let res = await categoryServices.getAll("ACTIVE");
          this.categoryList = res.data;
          if (this.categoryList.length > 100) //when session expired, response is text string, length is 108
            this.getCategories();
        } catch (err) {
          console.log("err ......" , err)
        }
      },

      async findByCategory(category,idx){
        this.buyers = [];
        this.loading = true;
        this.selectedIdx = idx;
        let res = await buyerUserServices.findByCategory(category);
        this.buyers = res.data;
        if (res.status== 200) 
          this.loading = false;
      }
    }
  };

  //tab-list
  $(document).ready(function(){
    $(".tab-button").click(function(){
      // 모든 탭의 "active" 클래스를 제거
      $(".tab-list a").removeClass("active");

      // 클릭한 탭에 "active" 클래스 추가
      $(this).addClass("active");

      var tabId = $(this).data("tab");
      $(".tab-content").hide();
      $("#" + tabId).show();
    });
  });
  </script>
  
  
  