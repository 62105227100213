var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { staticClass: "container" }, [
      _c("h4", [_vm._v(_vm._s(_vm.$t("gsp")))]),
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("address")) + " "),
      ]),
      _c("p", { staticClass: "mt-1" }, [
        _vm._v(" " + _vm._s(_vm.$t("copyright")) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }