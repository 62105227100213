var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout", { attrs: { template: _vm.$MixinLayout } }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", { staticClass: "text-center h1-txt" }, [_vm._v("Dashboard")]),
      _c("section", { staticClass: "dashboard buyer-offer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c("h2", { staticClass: "text-left my-4 h2-txt" }, [
              _vm._v(_vm._s(_vm.isBuyer() ? "Buying" : "Selling") + " Offer"),
            ]),
          ]),
          _vm.loading
            ? _c("div", { staticClass: "col-md-8 spinner-container" }, [
                _c("div", { staticClass: "spinner" }),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "metric-row metric-flush" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "offer-list" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" New Offer "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fa-solid fa-user" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.newOfferCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "offer-list" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Nego "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-comment" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.myNegoCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "quotation-request" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Quotation Request "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-quote-left" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.myQuotationRequestCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "quotation" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Quotation "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-quote-left" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.myQuotationCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "purchase-order" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v("Purchase Order "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-box" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.myPurchaseOrderCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "delivery-inspection" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Delivery "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-truck" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(" " + _vm._s(_vm.data.deliCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "dashboard-list" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-striped" }, [
              _c("caption", [
                _vm._v(" buyer offer list "),
                _c("strong", [
                  _vm._v(
                    "offer No, Offer Title, Due Date, seller count, seller, Status, Update Date info"
                  ),
                ]),
              ]),
              _c("colgroup", [
                _c("col", {
                  staticStyle: { width: "18%", "min-width": "100px" },
                }),
                _c("col", {
                  staticStyle: { width: "*%", "min-width": "120px" },
                }),
                _c("col", {
                  staticStyle: { width: "12%", "min-width": "100px" },
                }),
                _c("col", {
                  staticStyle: { width: "10%", "min-width": "140px" },
                }),
                _c("col", {
                  staticStyle: { width: "15%", "min-width": "180px" },
                }),
                _c("col", {
                  staticStyle: { width: "10%", "min-width": "80px" },
                }),
                _c("col", {
                  staticStyle: { width: "10%", "min-width": "140px" },
                }),
              ]),
              _c("thead", { staticClass: "thead-skyblue" }, [
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Offer No")]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v("Offer Title"),
                  ]),
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Due Date")]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v("Seller Count"),
                  ]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(" " + _vm._s(_vm.isBuyer() ? "SELLER" : "BUYER")),
                  ]),
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Status")]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v("Update Date"),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.createOffers, function (createOffer) {
                  return _c("tr", { key: `table-item-${createOffer.id}` }, [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(createOffer.requestNo)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(createOffer.requestName)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatMsDate")(createOffer.dueDate))
                      ),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(createOffer.supplierCount)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.getCompanyName(createOffer))),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _c(
                        "span",
                        {
                          class: _vm.getStatusBadgeClass(
                            createOffer.offerStatus
                          ),
                        },
                        [_vm._v(_vm._s(createOffer.offerStatus))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatMsDate")(createOffer.updatedDate))
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "container p-0" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-md-6" }),
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center justify-content-md-end mt-3 mt-md-2",
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "input-group" }, [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            attrs: {
                              type: "button",
                              disabled:
                                _vm.page === 1 ||
                                (_vm.createOffers &&
                                  _vm.createOffers.length === 0),
                            },
                            on: { click: _vm.prevPage },
                          },
                          [
                            _c("i", { staticClass: "bi bi-caret-left-fill" }),
                            _vm._v(" Previous "),
                          ]
                        ),
                      ]),
                      _c(
                        "span",
                        { staticClass: "input-group-text bg-white border-0" },
                        [
                          _c("small", [
                            _vm._v(" " + _vm._s(_vm.tableMetaInfo) + " "),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            attrs: {
                              type: "button",
                              disabled:
                                _vm.page === _vm.numberOfPages ||
                                (_vm.createOffers &&
                                  _vm.createOffers.length === 0),
                            },
                            on: { click: _vm.nextPage },
                          },
                          [
                            _vm._v(" Next "),
                            _c("i", { staticClass: "bi bi-caret-right-fill" }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "dashboard seller-offer" }, [
        _c("h2", { staticClass: "text-left my-4 h2-txt" }, [
          _vm._v(_vm._s(_vm.isBuyer() ? "Selling" : "Buying") + " Offer"),
        ]),
        _c("div", { staticClass: "metric-row metric-flush" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "offer-cart" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Offers "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fa-solid fa-user" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.appliedOfferCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "offer-cart" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Nego "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-comment" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.othersNegoCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "quotation-request" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Quotation Request"),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-quote-left" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.othersQuotationRequestCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "quotation" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Quotation "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-quote-left" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.othersQuotationCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "purchase-order" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Order "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-box" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.othersPurchaseOrderCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "router-link",
                {
                  staticClass: "metric metric-bordered align-items-center",
                  attrs: { to: "offer-cart" },
                },
                [
                  _c("strong", { staticClass: "metric-label" }, [
                    _vm._v(" Reject "),
                  ]),
                  _c("p", { staticClass: "metric-value h3" }, [
                    _c("sub", [_c("i", { staticClass: "fas fa-backspace" })]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.data.rejectedCartCount)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "dashboard-list" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-striped" }, [
              _c("caption", [
                _vm._v(" Selling offer list "),
                _c("strong", [
                  _vm._v(
                    "seller, office No, Offer Title, Due Date, Status, Update Date info"
                  ),
                ]),
              ]),
              _c("colgroup", [
                _c("col", {
                  staticStyle: { width: "15%", "min-width": "100px" },
                }),
                _c("col", {
                  staticStyle: { width: "18%", "min-width": "100px" },
                }),
                _c("col", {
                  staticStyle: { width: "*%", "min-width": "120px" },
                }),
                _c("col", {
                  staticStyle: { width: "12%", "min-width": "100px" },
                }),
                _c("col", {
                  staticStyle: { width: "10%", "min-width": "80px" },
                }),
                _c("col", {
                  staticStyle: { width: "10%", "min-width": "140px" },
                }),
              ]),
              _c("thead", { staticClass: "thead-skyblue" }, [
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.isBuyer() ? "SELLER" : "BUYER")),
                  ]),
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Offer No")]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v("Offer Title"),
                  ]),
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Due Date")]),
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Status")]),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v("Update Date"),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.cartOffers, function (cartOffer) {
                  return _c("tr", { key: `table-item-${cartOffer.id}` }, [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(cartOffer.offer.companyName)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(cartOffer.offer.requestNo)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(cartOffer.offer.requestName)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatMsDate")(cartOffer.offer.dueDate))
                      ),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _c(
                        "span",
                        {
                          class: _vm.getStatusBadgeClass(cartOffer.cartStatus),
                        },
                        [_vm._v(_vm._s(cartOffer.cartStatus))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatMsDate")(cartOffer.editedDate))
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "container p-0" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-md-6" }),
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center justify-content-md-end mt-3 mt-md-2",
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "input-group" }, [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            attrs: {
                              type: "button",
                              disabled:
                                _vm.cartPage === 1 ||
                                (_vm.cartOffers && _vm.cartOffers.length === 0),
                            },
                            on: { click: _vm.cartPrevPage },
                          },
                          [
                            _c("i", { staticClass: "bi bi-caret-left-fill" }),
                            _vm._v(" Previous "),
                          ]
                        ),
                      ]),
                      _c(
                        "span",
                        { staticClass: "input-group-text bg-white border-0" },
                        [
                          _c("small", [
                            _vm._v(" " + _vm._s(_vm.cartTableMetaInfo) + " "),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            attrs: {
                              type: "button",
                              disabled:
                                _vm.cartPage === _vm.cartNumberOfPages ||
                                (_vm.cartOffers && _vm.cartOffers.length === 0),
                            },
                            on: { click: _vm.cartNextPage },
                          },
                          [
                            _vm._v(" Next "),
                            _c("i", { staticClass: "bi bi-caret-right-fill" }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }