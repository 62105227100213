<template>
  <div style="height:inherit">
    <header>
      <public-header-nav></public-header-nav>
    </header>

    <slot></slot>

    <public-footer></public-footer>
  </div>
</template>

<script>
import PublicHeaderNav from "../components/PublicHeaderNav.vue";
import PublicFooter from "../components/Footer.vue";

export default {
  name: "PublicLayout",
  components: {
    PublicHeaderNav,
    PublicFooter,
  },
  data: () => ({}),
  computed: {},
  mounted() {},
  methods: {},
};
</script>
