var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-expand-lg navbar-light bg-light public-nav header-top",
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "router-link",
            { staticClass: "navbar-brand", attrs: { to: "/" } },
            [
              _c("img", {
                staticClass: "logo-img",
                attrs: {
                  src: require("../assets/images/only-logo.png"),
                  alt: "GSP",
                },
              }),
              _vm._v("Global Sourcing Platform "),
            ]
          ),
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse navbar-right",
              attrs: { id: "navbarNav" },
            },
            [
              _c("ul", { staticClass: "navbar-nav ml-auto" }, [
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link font-weight-bold",
                        attrs: { to: "/signin" },
                      },
                      [_vm._v(" Sign In ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link font-weight-bold",
                        attrs: { to: "/sign-up" },
                      },
                      [_vm._v(" Sign Up ")]
                    ),
                  ],
                  1
                ),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    { staticClass: "nav-link", attrs: { href: "#" } },
                    [_c("GoogleTranslateWidget")],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarNav",
          "aria-controls": "navbarNav",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }