<template>
  <nav class="
      navbar navbar-expand-md navbar-light
      bg-light
      elevation-2
      border-bottom
      shadow-sm
      dash-nav
      header-top
    ">
    <div class="container">
      <a class="navbar-brand">Global Sourcing Platform</a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse navbar-right" id="navbarNav">
        <ul class="navbar-nav ml-auto">

          <!-- <li class="nav-item"> hidden
            <a class="nav-link font-weight-bold faq" href="#">FAQ</a>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link font-weight-bold" :to="dashboard">
              Dashboard
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link font-weight-bold" :to="isBuyer() ? supplierSearch : buyersearch">
              {{ isBuyer() ? 'Supplier Search' : 'Buyer Search' }}
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <GoogleTranslateWidget />
            </a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link font-weight-bold" :to="profileRoute">
              <i class="bi bi-person-fill menu-icon"></i>
              <span class="notranslate">{{ authUser.username }}</span>
            </router-link>
          </li>

          <!--          <li class="nav-item dropdown">-->
          <!--            <a class="nav-link dropdown-toggle font-weight-bold" href="#" id="navbarDropdownMenuLink" role="button"-->
          <!--              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
          <!--              <i class="bi bi-justify menu-icon"></i>-->
          <!--              {{ $t('menu') }}-->
          <!--            </a>-->
          <!--            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">-->
          <!--              <template v-for="(menu, idx) in menuList">-->
          <!--                <h6 v-if="menu.header" :key="`menu-item-header-${idx}`" class="dropdown-header" v-text="menu.label"></h6>-->

          <!--                <div v-else-if="menu.divider" :key="`menu-item-divider-${idx}`" class="dropdown-divider"></div>-->
          <!--                <router-link v-else :key="`menu-item-${idx}`" class="dropdown-item" :to="menu.to">-->
          <!--                  <i :class="menu.icon"></i>-->
          <!--&lt;!&ndash;                  {{ $t(menu.label) }}&ndash;&gt;-->
          <!--                  <span :class="{ notranslate: noTranslateMenu(menu.label) }">{{ $t(menu.label) }}</span>-->
          <!--                </router-link>-->
          <!--              </template>-->

          <!--              &lt;!&ndash; action &ndash;&gt;-->
          <!--              <div class="dropdown-divider"></div>-->
          <!--              <h6 class="dropdown-header">{{ $t('action') }} </h6>-->
          <!--              &lt;!&ndash; "change password" option is changed by Vlad tobe disabled in case authentication method is NEW &ndash;&gt;-->
          <!--              <router-link v-show="$store.getters.authUser.method !== 'NEW'" class="dropdown-item"-->
          <!--                :to="{ name: 'auth-user-change-password', params: { username: authUser.username } }"><i-->
          <!--                  class="bi bi-lock"></i> {{ $t('changePassword') }} </router-link>-->
          <!--              <a class="dropdown-item" href="#" @click="logout"><i class="bi-box-arrow-right pd4"></i><span class="pd4">{{-->
          <!--                $t('logout') }}</span></a>-->
          <!--            </div>-->
          <!--          </li>-->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle font-weight-bold" href="#" id="navbarDropdownMenuLink" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="bi bi-justify menu-icon"></i>
              {{ $t('menu') }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

              <template v-for="(menu, idx) in menuList">

                <h6 v-if="menu.header" :key="`menu-item-header-${idx}`" class="dropdown-header" v-text="menu.label"></h6>

                <div v-else-if="menu.divider" :key="`menu-item-divider-${idx}`" class="dropdown-divider"></div>

                <!-- Menu item with sub-menu -->
                <div
                    v-else-if="menu.subMenu"
                    :key="`menu-item-${idx}`"
                    class="dropdown-item"
                    @mouseover="openSubMenu(idx)"
                    @mouseleave="closeSubMenu()"
                    style="cursor: pointer"
                >
                  <router-link
                      :key="`menu-item-${idx}`"
                      :to="menu.to || null"
                      class="main-menu-style"
                  >
                    <i :class="menu.icon"></i>
                    <span :class="{ notranslate: noTranslateMenu(menu.label) }">{{ $t(menu.label) }}</span>
                  </router-link>

                  <!-- Sub-menu -->
                  <div
                      v-show="isSubMenuOpen(idx)"
                      class="sub-menu"
                      @mouseover="keepSubMenuOpen(idx)"
                      @mouseleave="closeSubMenu(idx)"
                  >
                    <router-link
                        v-for="(subMenuItem, subIdx) in menu.subMenu"
                        :key="`sub-menu-item-link-${subIdx}`"
                        class="dropdown-item"
                        :to="subMenuItem.to"

                    >
                      <i :class="subMenuItem.icon"></i>
                      <span :class="{ notranslate: noTranslateMenu(subMenuItem.label) }">{{ $t(subMenuItem.label) }}</span>
                    </router-link>
                  </div>
                </div>

                <!-- Regular menu item with navigation link -->
                <router-link v-else :key="`menu-item-${idx}`" class="dropdown-item" :to="menu.to || null">
                  <i :class="menu.icon"></i>
                  <span :class="{ notranslate: noTranslateMenu(menu.label) }">{{ $t(menu.label) }}</span>
                </router-link>
              </template>

              <!-- action -->
              <div class="dropdown-divider"></div>
              <h6 class="dropdown-header">{{ $t('action') }} </h6>
              <!-- "change password" option is changed by Vlad tobe disabled in case authentication method is NEW -->
              <router-link v-show="$store.getters.authUser.method !== 'NEW'" class="dropdown-item"
                           :to="{ name: 'auth-user-change-password', params: { username: authUser.username } }"><i
                  class="bi bi-lock"></i> {{ $t('changePassword') }} </router-link>
              <a class="dropdown-item" href="#" @click="logout"><i class="bi-box-arrow-right pd4"></i><span class="pd4">{{
                  $t('logout') }}</span></a>
            </div>
          </li>
          <li class="nav-item" style="width: 100px;"></li>
          <li class="nav-item" style="width: 100px;"></li>

        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { ROLE } from "../helpers/role";
import {
  getAdminMenu,
  getBuyerAdminMenu,
  getBuyerUserAdminMenu,
  getBuyerUserGeneralMenu,
  getSupplierAdminMenu,
  getSupplierUserAdminMenu,
  getSupplierUserGeneralMenu,
} from "../helpers/menus";
import { LOGOUT } from "../store/action.types";
import GoogleTranslateWidget from "@/components/public/GoogleTranslateWidget.vue";
import dashboard from "@/views/Dashboard.vue";
import buyerSearch from "@/views/BuyerSearch.vue";
import supplierSearch from "@/views/SupplierSearch.vue";
import {isBuyer} from "@/helpers/common";

export default {
  components: { GoogleTranslateWidget },

  data: () => ({
    menuList: [],
    profileRoute: {},
    activeSubMenu: null,
    activeSubMenuItem: null
  }),
  computed: {
    dashboard() {
      return dashboard
    },
    buyersearch() {
      return buyerSearch
    },
    supplierSearch() {
      return supplierSearch
    },
    authUser() {
      return this.$store.getters.authUser;
    },
  },
  created() {
    this.getMenu();
  },
  methods: {
    isBuyer,
    openSubMenu(index) {
      this.activeSubMenu = index;
    },
    closeSubMenu() {
      this.activeSubMenu = null;
    },
    keepSubMenuOpen(index) {
      this.activeSubMenu = index;
    },
    isSubMenuOpen(index) {
      return this.activeSubMenu === index;
    },
    isSubMenuItemActive(menuIndex, subMenuIndex) {
      return this.activeSubMenu === menuIndex && this.activeSubMenuItem === subMenuIndex;
    },
    noTranslateMenu(label){
      if(label === 'KYB' || label === 'Quotation' || label === 'Usance'){
        return true;
      }
      return false;
    },
    getMenu() {
      if (this.authUser.role && this.authUser.role.roleName) {
        switch (this.authUser.role.roleName) {
          case ROLE.Admin:
            this.menuList = getAdminMenu(this.authUser);
            break;

          case ROLE.BuyerAdmin:
            this.menuList = getBuyerAdminMenu(this.authUser);
            this.profileRoute = {
              name: "auth-buyer-admin-profile",
              params: { username: this.authUser.username },
            };
            break;

          case ROLE.SupplierAdmin:
            this.menuList = getSupplierAdminMenu(this.authUser);
            this.profileRoute = {
              name: "auth-supplier-admin-profile",
              params: { username: this.authUser.username },
            };
            break;

          case ROLE.BuyerUserAdmin:
            this.menuList = getBuyerUserAdminMenu(this.authUser);
            break;

          case ROLE.BuyerGeneralUser:
            this.menuList = getBuyerUserGeneralMenu(this.authUser);
            this.profileRoute = {
              name: "auth-buyer-user-profile",
              params: { username: this.authUser.username },
            };
            break;

          case ROLE.SupplierUserAdmin:
            this.menuList = getSupplierUserAdminMenu(this.authUser);
            break;

          case ROLE.SupplierGeneralUser:
            this.menuList = getSupplierUserGeneralMenu(this.authUser);
            this.profileRoute = {
              name: "auth-supplier-user-profile",
              params: { username: this.authUser.username },
            };
            break;

          default:
            this.menuList = [
              {
                header: true,
                label: "No Menu",
              },
            ];
            break;
        }
      }
    },
    logout() {
      this.$store
          .dispatch(LOGOUT)
          .then(() => {
            this.$router.push("/").catch((err) => {
              console.error(err);
              this.$router.push("/");
            });
          })
          .catch(() => { });
    },
  },
};
</script>

<style>
.main-menu-style{
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the color of the parent element */
}
.main-menu-style:hover {
  text-decoration: none !important; /* Remove underline on hover */
  color: inherit !important; /* Use the color of the parent element on hover */
}
</style>
