import apiService from "./api.services";

const ecSuppliersService = {
    getECSuppliers(searchParams, page, size) {
        if (!page) page = 0
        else page -= 1

        if (!size) size = 50

        let params = { page, size }

        for (const [key, value] of Object.entries(searchParams)) if (value && key !== 'page' && key !== 'size') params[key] = value

        return apiService.get('/auth/ec-suppliers' , params);
    },
}

export default ecSuppliersService;
