var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("nprogress-container"),
      _c(
        _vm.layout,
        { tag: "component" },
        [
          _c("router-view", {
            attrs: { layout: _vm.layout },
            on: {
              "update:layout": function ($event) {
                _vm.layout = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }