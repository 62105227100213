// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/Roboto-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/Roboto-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n@font-face {\r\n  font-family: 'Roboto';\r\n  /* changed by vlad to prevent fonts download from goole, we use it locally */\r\n  src: local('Roboto'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype'), local('Roboto'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype')\n}\r\n", ""]);
// Exports
module.exports = exports;
