/**
 * Read more: https://markus.oberlehner.net/blog/dynamic-vue-layout-components/
 * mirror: https://web.archive.org/web/20210713103509/https://markus.oberlehner.net/blog/dynamic-vue-layout-components/
 * */

import Vue from 'vue';

export default {
  name: 'Layout',
  props: {
    template: {
      type: String,
      required: true
    }
  },
  created() {
    // Check if the layout component
    // has already been registered.
    if (!Vue.options.components[this.template]) {
      Vue.component(this.template, () =>
        import(
          /* webpackChunkName: "noprefetch-[request]" */ `../layouts/${this.template}.vue`
        )
      );
    }

    this.$parent.$emit('update:layout', this.template);
  },
  render() {
    return this.$slots.default[0];
  }
};
