import adminMenu from "./AdminMenu";
import buyerAdminMenu from "./BuyerAdminMenu";
import supplierAdminMenu from "./SupplierAdminMenu";
import buyerUserGeneralMenu from "./BuyerUserGeneralMenu";
import buyerUserAdminMenu from "./BuyerUserAdminMenu";
import supplierUserGeneralMenu from "./SupplierUserGeneralMenu";
import supplierUserAdminMenu from "./SupplierUserAdminMenu";

export const getAdminMenu = adminMenu
export const getBuyerAdminMenu = buyerAdminMenu
export const getSupplierAdminMenu = supplierAdminMenu
export const getBuyerUserGeneralMenu = buyerUserGeneralMenu
export const getBuyerUserAdminMenu = buyerUserAdminMenu
export const getSupplierUserGeneralMenu = supplierUserGeneralMenu
export const getSupplierUserAdminMenu = supplierUserAdminMenu
