export default (authUser) => ([
  {
    header: true,
    label: 'Menu'
  },
  {
    icon: 'bi bi-person-lines-fill',
    label: 'Supplier User',
    to: {
      name: 'auth-supplier-user-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Offers',
    to: {
      name: 'offer-list',
      params: { username: authUser.username }
    },
    subMenu: [
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Offer Board',
        to: {
          name: 'offer-board',
          params: { username: authUser.username }
        }
      },
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Offer Cart',
        to: {
          name: 'offer-cart',
          params: { username: authUser.username }
        }
      },
    ]
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Supplier Entry',
    to: {
      name: 'auth-supplier-entry-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Quotation Request',
    to: {
      name: 'auth-quotation-request-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Quotation',
    to: {
      name: 'auth-quotation-list',
      params: { username: authUser.username }
    },
    subMenu: [
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Quotation History',
        to: {
          name: 'auth-quotation-history-list',
          params: { username: authUser.username }
        }
      },
    ]
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Purchase Order',
    to: {
      name: 'auth-purchase-order-list',
      params: { username: authUser.username }
    },
    subMenu: [
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Purchase Order History',
        to: {
          name: 'auth-purchase-order-history-list',
          params: { username: authUser.username }
        }
      },
    ]
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Delivery Inspection',
    to: {
      name: 'auth-delivery-inspection-list',
      params: { username: authUser.username }
    },
    subMenu: [
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Delivery Inspection History',
        to: {
          name: 'auth-delivery-inspection-history-list',
          params: { username: authUser.username }
        }
      },
    ]
  },
])
