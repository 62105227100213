import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth.modules';
import qrcode from './modules/qrcode.modules';//added by Vlad to provide qrcode related logic
import createPersistedState from 'vuex-persistedstate';
import locale from "@/store/modules/locale.modules";

Vue.use(Vuex);

export default new Vuex.Store( {
    modules: { auth, qrcode, locale },
    plugins: [
        createPersistedState({
            key: 'persistedLocale', // Use a key specific to the locale module
            paths: ['locale'], // Specify the module you want to persist
            storage: window.localStorage,
        }),
    ],
});


