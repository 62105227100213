<template>
  <layout :template="$MixinLayout">
    <div class="container">
      <h1 class="text-center  h1-txt">Dashboard</h1>


      <section class="dashboard buyer-offer">
        <div class="row">
          <div class="col-md-2">
          <h2 class="text-left my-4 h2-txt">{{ isBuyer() ? 'Buying' : 'Selling' }} Offer</h2></div>
          <div v-if="loading" class="col-md-8 spinner-container">
            <div class="spinner"></div>
          </div>
        </div>
        <!-- metric row -->
        <div class="metric-row metric-flush">
          <!-- metric column -->
          <div class="col">
            <!-- .metric -->
            <router-link class="metric metric-bordered align-items-center" to="offer-list">
              <strong class="metric-label"> New Offer </strong>
              <p class="metric-value h3">
                <sub><i class="fa-solid fa-user"></i></sub> <span class="value">{{ data.newOfferCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- / .metric column -->
          <!-- metric column -->
          <div class="col">
            <!-- metric -->
            <router-link class="metric metric-bordered align-items-center" to="offer-list">
              <strong class="metric-label"> Nego </strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-comment"></i></sub> <span class="value">{{ data.myNegoCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- /.metric column -->
          <div class="col">
            <!-- .metric -->
            <router-link class="metric metric-bordered align-items-center" to="quotation-request">
              <strong class="metric-label"> Quotation Request </strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-quote-left"></i></sub> <span class="value">{{ data.myQuotationRequestCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- metric column -->
          <div class="col">
            <!-- .metric -->
            <router-link class="metric metric-bordered align-items-center" to="quotation">
              <strong class="metric-label"> Quotation </strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-quote-left"></i></sub> <span class="value">{{ data.myQuotationCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- metric column -->
          <div class="col">
            <!-- .metric -->
            <router-link class="metric metric-bordered align-items-center" to="purchase-order">
              <strong class="metric-label">Purchase Order </strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-box"></i></sub> <span class="value">{{ data.myPurchaseOrderCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- /metric column -->
          <!-- metric column -->
          <div class="col">
            <!-- .metric -->
            <router-link class="metric metric-bordered align-items-center" to="delivery-inspection">
              <strong class="metric-label"> Delivery </strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-truck"></i></sub> <span class="value"> {{ data.deliCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- /metric column -->
        </div>
        <!-- /metric row -->
        <!-- list -->


        <div class="dashboard-list">
          <div class="table-responsive">
            <table class="table table-striped">
              <caption>
                buyer offer list
                <strong>offer No, Offer Title, Due Date, seller count, seller, Status, Update Date info</strong>
              </caption>
              <colgroup>
                <col style="width: 18%; min-width: 100px" />
                <col style="width: *%; min-width: 120px" />
                <col style="width: 12%; min-width: 100px" />
                <col style="width: 10%; min-width: 140px" />
                <col style="width: 15%; min-width: 180px" />
                <col style="width: 10%; min-width: 80px" />
                <col style="width: 10%; min-width: 140px" />
              </colgroup>
              <thead class="thead-skyblue">
                <tr>
                  <th scope="col">Offer No</th>
                  <th scope="col">Offer Title</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Seller Count</th>
                  <th scope="col"> {{ isBuyer() ? 'SELLER' : 'BUYER' }}</th>
                  <th scope="col">Status</th>
                  <th scope="col">Update Date</th>
                </tr>
              </thead>
              <tbody>
              <!--end loading wrapper-->
              <tr v-for="createOffer in createOffers" :key="`table-item-${createOffer.id}`">
                <td class="text-center">{{ createOffer.requestNo }}</td>
                <td class="text-center">{{ createOffer.requestName }}</td>
                <td class="text-center">{{ createOffer.dueDate | formatMsDate  }}</td>
                <td class="text-center">{{ createOffer.supplierCount }}</td>
                <td class="text-center">{{ getCompanyName(createOffer) }}</td>
                <td class="text-center">
                  <span :class="getStatusBadgeClass(createOffer.offerStatus)">{{ createOffer.offerStatus }}</span>
                </td>
                <td class="text-center">{{ createOffer.updatedDate | formatMsDate }}</td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
        <!-- // list -->

        <!-- list bottom control -->
        <div class="container p-0">
          <div class="row">
            <div class="col-12 col-md-6">
            </div>
            <!-- page control -->
            <div class="col-12 col-md-6">
              <div class="d-flex justify-content-center justify-content-md-end mt-3 mt-md-2">
                <div>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button class="btn btn-link" type="button"
                        :disabled="page === 1 || (createOffers && createOffers.length === 0)" @click="prevPage">
                        <i class="bi bi-caret-left-fill"></i> Previous
                      </button>
                    </div>
                    <span class="input-group-text bg-white border-0"><small> {{ tableMetaInfo }} </small></span>
                    <div class="input-group-append">
                      <button class="btn btn-link" type="button"
                        :disabled="page === numberOfPages || (createOffers && createOffers.length === 0)"
                        @click="nextPage">
                        Next <i class="bi bi-caret-right-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end pagination-->

      </section>
      <!-- list -->
      <section class="dashboard seller-offer">
        <h2 class="text-left my-4 h2-txt">{{ isBuyer() ? 'Selling' : 'Buying' }} Offer</h2>
        <!-- metric row -->
        <div class="metric-row metric-flush">
          <div class="col">
            <!-- metric -->
            <router-link class="metric metric-bordered align-items-center" to="offer-cart">
              <strong class="metric-label"> Offers </strong>
              <p class="metric-value h3">
                <sub><i class="fa-solid fa-user"></i></sub> <span class="value">{{ data.appliedOfferCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- metric column -->
          <div class="col">
            <!-- metric -->
            <router-link class="metric metric-bordered align-items-center" to="offer-cart">
              <strong class="metric-label"> Nego </strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-comment"></i></sub> <span class="value">{{ data.othersNegoCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- /.metric column -->
          <!-- metric column -->
          <div class="col">
            <!-- .metric -->
            <router-link class="metric metric-bordered align-items-center" to="quotation-request">
              <strong class="metric-label"> Quotation Request</strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-quote-left"></i></sub> <span class="value">{{ data.othersQuotationRequestCount
                }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- /.metric column -->
          <!-- metric column -->
          <div class="col">
            <!-- .metric -->
            <router-link class="metric metric-bordered align-items-center" to="quotation">
              <strong class="metric-label"> Quotation </strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-quote-left"></i></sub> <span class="value">{{ data.othersQuotationCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- /metric column -->
          <!-- metric column -->
          <div class="col">
            <!-- .metric -->
            <router-link class="metric metric-bordered align-items-center" to="purchase-order">
              <strong class="metric-label"> Order </strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-box"></i></sub> <span class="value">{{ data.othersPurchaseOrderCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- /metric column -->
          <!-- metric column -->
          <div class="col">
            <!-- .metric -->
            <router-link class="metric metric-bordered align-items-center" to="offer-cart">
              <strong class="metric-label"> Reject </strong>
              <p class="metric-value h3">
                <sub><i class="fas fa-backspace"></i></sub> <span class="value">{{ data.rejectedCartCount }}</span>
              </p>
            </router-link>
            <!-- /.metric -->
          </div>
          <!-- /metric column -->
        </div>
        <!-- /metric row -->
        <!-- list -->
        <div class="dashboard-list">
          <div class="table-responsive">
            <table class="table table-striped">
              <caption>
                Selling offer list
                <strong>seller, office No, Offer Title, Due Date, Status, Update Date info</strong>
              </caption>
              <colgroup>
                <col style="width: 15%; min-width: 100px" />
                <col style="width: 18%; min-width: 100px" />
                <col style="width: *%; min-width: 120px" />
                <col style="width: 12%; min-width: 100px" />
                <col style="width: 10%; min-width: 80px" />
                <col style="width: 10%; min-width: 140px" />
              </colgroup>
              <thead class="thead-skyblue">
                <tr>
                  <th scope="col">{{ isBuyer() ? 'SELLER' : 'BUYER' }}</th>
                  <th scope="col">Offer No</th>
                  <th scope="col">Offer Title</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Update Date</th>
                </tr>
              </thead>
              <tbody>

              <tr v-for="cartOffer in cartOffers" :key="`table-item-${cartOffer.id}`">
                <td class="text-center">{{ cartOffer.offer.companyName }}</td>
                <td class="text-center">{{ cartOffer.offer.requestNo }}</td>
                <td class="text-center">{{ cartOffer.offer.requestName }}</td>
                <td class="text-center">{{ cartOffer.offer.dueDate | formatMsDate }}</td>
                <td class="text-center">
                  <span :class="getStatusBadgeClass(cartOffer.cartStatus)">{{ cartOffer.cartStatus }}</span>
                </td>
                <td class="text-center">{{ cartOffer.editedDate | formatMsDate }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- // list -->

        <!-- list bottom control -->
        <div class="container p-0">
          <div class="row">
            <div class="col-12 col-md-6">
            </div>
            <!-- page control -->
            <div class="col-12 col-md-6">
              <div class="d-flex justify-content-center justify-content-md-end mt-3 mt-md-2">
                <div>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button class="btn btn-link" type="button"
                        :disabled="cartPage === 1 || (cartOffers && cartOffers.length === 0)" @click="cartPrevPage">
                        <i class="bi bi-caret-left-fill"></i> Previous
                      </button>
                    </div>
                    <span class="input-group-text bg-white border-0"><small> {{ cartTableMetaInfo }} </small></span>
                    <div class="input-group-append">
                      <button class="btn btn-link" type="button"
                        :disabled="cartPage === cartNumberOfPages || (cartOffers && cartOffers.length === 0)"
                        @click="cartNextPage">
                        Next <i class="bi bi-caret-right-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- list -->

    </div>
  </layout>
</template>

<script>


import layoutMixin from "@/mixins/layout.mixins";
import dashboardService from "../services/dashboard.services";
import offerService from "@/services/offer.services";
import {formatMsDate} from "@/filters/date.filters";
import {stringHumanize} from "@/filters/string.filters";
import {OFFER_STATUS} from "@/helpers/offerStatus";
import {isBuyer} from "@/helpers/common";

export default {

  name: "auth-dashboard",
  mixins: [layoutMixin],
  computed: {
    authUser() {
      return this.$store.getters.authUser;
    },
  },
  components: {},
  filters: {formatMsDate, stringHumanize},
  data() {
    return {
      searchParams:
      {
        requestName: "",
        requestNumber: "",
        categoryId: 0,
        fromDueDateStr: "",
        toDueDateStr: "",
        offerStatus: "",
        fromAddedDate: "",
        toAddedDate: "",
        userId: ""
      },
      createOffers: [],
      tableMetaInfo: "",
      itemsPerPageOptions: [25, 50, 100],
      itemsPerPage: 5,
      page: 1,
      totalItemsLength: 0,
      numberOfPages: 0,
      numberOfElements: 0,
      cartOffers: [],
      cartTableMetaInfo: "",
      cartPage: 1,
      cartTotalItemsLength: 0,
      cartNumberOfPages: 0,
      cartNumberOfElements: 0,
      data: {},
      sort: null,
      loading: false,
    }
  },
  created() {
    this.getDashboard();
  },
  mounted() {
    this.sort = "updatedDate,desc";
    this.searchParams.userId = this.authUser.id;
    this.searchOffers();
  },
  methods: {
    isBuyer,
    getBootstrapBadgeClass(status) {
      // Map your custom statuses to Bootstrap badge classes
      const statusClassMap = {
        Draft: 'secondary',
        Posting: 'primary',
        Unread: 'warning',
        Read: 'success',
        Nego: 'info',
        QuotationRequest: 'warning',
        Reject: 'danger',
        Contract: 'success',
        Close: 'secondary'
      };
      return statusClassMap[status] || 'danger';
    },
    getStatusBadgeClass(cartStatus) {
      const statusObject = OFFER_STATUS.find(status => status.value === cartStatus);

      if (statusObject) {
        return `badge badge-${this.getBootstrapBadgeClass(statusObject.label)}`;
      } else {
        return 'badge badge-danger';
      }
    },
    getCompanyName(offer) {
      if (this.isBuyer() && offer.supplierSignIn) {
        return offer.supplierSignIn.companyName;
      } else if (!this.isBuyer() && offer.buyerSignIn) {
        return offer.buyerSignIn.companyName;
      } else {
        return 'N/A';
      }
    },
    nextPage() {
      this.page += 1;
      this.fetchOffers(this.searchParams, this.page, this.itemsPerPage, this.sort);
    },
    prevPage() {
      this.page -= 1;
      this.fetchOffers(this.searchParams, this.page, this.itemsPerPage, this.sort);
    },
    cartNextPage() {
      this.cartPage += 1;
      this.fetchOfferCart(this.searchParams, this.cartPage, this.itemsPerPage, this.sort);
    },
    cartPrevPage() {
      this.cartPage -= 1;
      this.fetchOfferCart(this.searchParams, this.cartPage, this.itemsPerPage, this.sort);
    },
    async getDashboard() {
      this.loading = true;
      let res = await dashboardService.getDashboard(this.authUser.id);
      if (res.data.status) {
        this.data = res.data;
        this.loading = false;
      }
    },
    searchOffers() {
      this.$router
          .replace({
            name: "auth-dashboard",
            params: {username: this.authUser.username},
            query: {
              ...this.searchParams,
              page: this.page,
              size: this.itemsPerPage,
              sort: this.sort,
            },
          })
          .catch(() => {
          });
      this.fetchOffers(this.searchParams, this.page, this.itemsPerPage, this.sort);
      this.fetchOfferCart(this.searchParams, this.cartPage, this.itemsPerPage, this.sort);

    },
    async fetchOffers(searchParams, page, itemsPerPage, sort) {
      try {
        const result = await offerService.fetchOffers(searchParams, page, itemsPerPage, sort, this.authUser.id);
        if (!result.data.status) {
          this.listLoading = false;
          return;
        }
        this.createOffers = result.data.items;
        this.totalItemsLength = result.data.totalElements;
        this.numberOfPages = result.data.totalPages;
        this.numberOfElements = result.data.numberofElements;

        this.getTableMetaInfo();
        if ((this.numberOfPages > 0 && this.page > this.numberOfPages) || (this.numberOfPages > 0 && this.page < 1)) {
          this.page = 1;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async fetchOfferCart(searchParams, page, size, sort) {
      let result = await offerService.getOfferCarts(searchParams, page, size, sort);
      this.cartOffers = result.data.items;
      this.cartTotalItemsLength = result.data.totalElements;
      this.cartNumberOfPages = result.data.totalPages;
      this.cartNumberOfElements = result.data.numberofElements;
      this.getCartTableMetaInfo();
      if ((this.cartNumberOfPages > 0 && this.cartPage > this.cartNumberOfPages) || (this.cartNumberOfPages > 0 && this.cartPage < 1)) {
        this.cartPage = 1;
      }
    },
    getTableMetaInfo() {
      let startItem = this.itemsPerPage * (this.page - 1) + 1,
        endItem = this.itemsPerPage * (this.page - 1) + this.numberOfElements;
      this.tableMetaInfo = `${startItem}-${endItem} of ${this.totalItemsLength}`;
    },
    getCartTableMetaInfo() {
      let startItem = this.itemsPerPage * (this.cartPage - 1) + 1,
        endItem = this.itemsPerPage * (this.cartPage - 1) + this.cartNumberOfPages;
      this.cartTableMetaInfo = `${startItem}-${endItem} of ${this.cartTotalItemsLength}`;
    }
  }
}
</script>

<style>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 5px solid #e0e0e0;
  border-bottom: 5px solid #1686fe;
  animation: spin 1s linear infinite;
  position: relative;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>