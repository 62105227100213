export default {
  computed: {
    $MixinLayout() {
      return this.$route.meta.layout;
    },
  },
  beforeMount() {
    this.$emit("update:layout", this.$MixinLayout);
  },
}
