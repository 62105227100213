import format from 'date-fns/format';

export const formatMsDate = (date) => {
  return format(new Date(date), 'yyyy/MM/dd');
};

export const formatMsDateTime = (date) => {
  return format(new Date(date), 'dd MMM yyyy p');
};

export const dateFormat = (date, dateFormat) => {
  return format(new Date(date), dateFormat);
};
