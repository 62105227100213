export default (authUser) => ([
  {
    header: true,
    label: 'Menu'
  },
  
  {
    icon: 'bi bi-person-fill',
    label: 'Buyer User',
    to: {
      name: 'auth-buyer-user-list',
      params: { username: authUser.username }
    }
  },
])
