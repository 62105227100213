
export default (authUser) => ([
  {
    header: true,
    label: 'Menu'
  },
  // {
  //   icon: 'bi bi-envelope-fill',
  //   label: 'invitation',
  //   to: {
  //     name: 'auth-buyer-user-list',
  //     params: { username: authUser.username }
  //   }
  // },
  {
    icon: 'bi bi-person-lines-fill',
    label: 'buyerUser',
    to: {
      name: 'auth-buyer-user-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Offers',
    to: {
      name: 'offer-list',
      params: { username: authUser.username }
    },
    subMenu: [
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Offer Board',
        to: {
          name: 'offer-board',
          params: { username: authUser.username }
        }
      },
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Offer Cart',
        to: {
          name: 'offer-cart',
          params: { username: authUser.username }
        }
      },
    ]
  },

  // {
  //   icon: 'bi bi-file-earmark-text-fill',
  //   label: 'Offer Board',
  //   to: {
  //     name: 'offer-board',
  //     params: { username: authUser.username }
  //   }
  // },
  // {
  //   icon: 'bi bi-file-earmark-text-fill',
  //   label: 'Offer Cart',
  //   to: {
  //     name: 'offer-cart',
  //     params: { username: authUser.username }
  //   }
  // },
  // {
  //   icon: 'bi bi-file-earmark-text-fill',
  //   label: 'Offer',
  //   to: {
  //     name: 'offer-list',
  //     params: { username: authUser.username }
  //   }
  // },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'supplierEntry',
    to: {
      name: 'auth-supplier-entry-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Purchase Project',
    to: {
      name: 'auth-purchase-project-list',
      params: { username: authUser.username }
    }
  },
  { //Added by Vlad to enable KYB
    icon: 'bi bi-file-earmark-text-fill',
    label: 'KYB',
    to:
        {
          name: 'auth-KYB-list',
          params: { username: authUser.username }
        }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Quotation Request',
    to: {
      name: 'auth-quotation-request-list',
      params: { username: authUser.username }
    }
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Quotation',
    to: {
      name: 'auth-quotation-list',
      params: { username: authUser.username }
    },
    subMenu: [
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Quotation History',
        to: {
          name: 'auth-quotation-history-list',
          params: { username: authUser.username }
        }
      },
    ]
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Purchase Order',
    to: {
      name: 'auth-purchase-order-list',
      params: { username: authUser.username }
    },
    subMenu: [
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Purchase Order History',
        to: {
          name: 'auth-purchase-order-history-list',
          params: { username: authUser.username }
        }
      },
    ]
  },
  {
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Delivery Inspection',
    to: {
      name: 'auth-delivery-inspection-list',
      params: { username: authUser.username }
    },
    subMenu: [
      {
        icon: 'bi bi-file-earmark-text-fill',
        label: 'Delivery Inspection History',
        to: {
          name: 'auth-delivery-inspection-history-list',
          params: { username: authUser.username }
        }
      },
    ]
  },
  { //Added by Vlad to enable USANCE
    icon: 'bi bi-file-earmark-text-fill',
    label: 'Usance',
    to:
        {
          name: 'auth-USANCE-list',
          params: { username: authUser.username }
        }
  },
  {
    header: true,
    label: 'Invitation'
  },
  {
    icon: 'bi bi-envelope-fill',
    label: 'invitation',
    to: {
      name: 'supplier-invitation',
    }
  },
])

