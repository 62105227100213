var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "inherit" } },
    [
      _c("header", [_c("public-header-nav")], 1),
      _vm._t("default"),
      _c("public-footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }