<template>
  <div id="app">
    <nprogress-container></nprogress-container>

    <component :is="layout">
      <router-view :layout.sync="layout" />
    </component>
  </div>
</template>

<script>
import NprogressContainer from "vue-nprogress/src/NprogressContainer";

export default {
  name: "App",
  components: {
    NprogressContainer,
  },
  data: () => ({
    layout: "div",
  }),
};
</script>

<style scoped>
@font-face {
  font-family: 'Roboto';
  /* changed by vlad to prevent fonts download from goole, we use it locally */
  src: local('Roboto'), url('./assets/fonts/Roboto-Light.ttf') format('truetype'), local('Roboto'), url('./assets/fonts/Roboto-Bold.ttf') format('truetype')
}
</style>
