<template>
  <layout :template="$MixinLayout">
    <div class="container">
      <h3 class="text-center my-4">Supplier Search</h3>
      <!-- filter-->
      <div class="card bg-light mb-4">
        <form class="" @submit.prevent="searchSuppliers">
          <!-- filter md and up -->
          <div class="card-body d-none d-md-block">
            <div class="input-group form-row">
              <input type="text" class="form-control" placeholder="Type a Keyword to Search" v-model="searchParams.keyword" />
              <div class="input-group-append" v-if="isSearched">
                <button class="btn btn-outline-danger" type="button" @click="clearSearch()">
                  <i class="bi bi-x"></i> Clear
                </button>
              </div>
              <div class="input-group-append">
                <button class="btn btn-primary" type="submit">
                  <i class="bi bi-search"></i> Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- response message -->
      <div v-if="responseMsg.show" class="alert fade" :class="{
        show: responseMsg && responseMsg.show,
        'alert-danger': responseMsg && responseMsg.error, 'alert-success': responseMsg && !responseMsg.error,
      }" role="alert">{{ responseMsg.message || "!" }}
        <button type="button" class="close" @click="responseMsg = { show: false }" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="table-responsive">
        <table class="table table-striped">
          <caption>
            Supplier Search
            <strong>company name, product name, category, country,contact name, phone, address</strong>
          </caption>
          <colgroup>
            <col style="width: 20%; min-width: 120px" />
            <col style="width: 20%; min-width: 100px" />
            <col style="width: 25%; min-width: 150px" />
            <col style="width: *%; min-width: 100px" />
            <col style="width: 15%; min-width: 100px" />
            <col style="width: 15%; min-width: 100px" />
            <col style="width: 20%; min-width: 200px" />
          </colgroup>
          <thead class="thead-skyblue">
          <tr>
            <th scope="col">Company Name</th>
            <th scope="col">Product Name</th>
            <th scope="col">Main Items</th>
            <th scope="col">Country</th>
            <th scope="col">Contact Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Address</th>
          </tr>
          </thead>
          <tbody>
          <!-- loading wrapper -->
          <template v-if="listLoading">
            <tr>
              <td colspan="7">
                <div class="text-center">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          <!-- no data wrapper -->
          <template v-else-if="!listLoading && suppliers.length === 0">
            <tr>
              <td colspan="7">
                <div class="text-center">No data</div>
              </td>
            </tr>
          </template>

          <!-- list wrapper -->
          <template v-else>
            <tr v-for="supplier in suppliers" :key="`table-item-${supplier.id}`" class="border-line">
              <td class="pt-3 notranslate text-center">
                <div class="logo-container">
                  <!-- Check if logo_image exists and display it -->
                  <img v-if="supplier.logoImage" :src="supplier.logoImage" alt="Supplier Logo" class="img-fluid logo"
                       style="max-height: 50px; max-width: 50px;">
                </div>
                <div class="supplier-details">
                  <a :href="supplier.minisite || 'javascript:void(0)'" target="_blank">{{ supplier.name }}</a>
                </div>
              </td>
              <td class="pt-3 notranslate text-center">{{ supplier.product ? supplier.product.name : ""}}</td>
              <td class="pt-3 notranslate text-center">{{ supplier.mainItem }}</td>
              <td class="pt-3 notranslate text-center">{{ supplier.countryName }}</td>
              <td class="pt-3 notranslate text-center">{{ supplier.contact }}</td>
              <td class="pt-3 notranslate text-center">{{ supplier.phone }}</td>
              <td class="pt-3 notranslate text-center">{{ supplier.address }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>

      <!-- list bottom control -->
      <div class="container p-0">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-row mt-3 mt-md-2">
              <div class="col">
                <select class="form-control form-control-sm" v-model="itemsPerPage" @change="searchSuppliers">
                  <option v-for="(ippOpt, idx) in itemsPerPageOptions" :key="`ippOpt-selectitem-${idx}`" :value="ippOpt"
                          v-text="`${ippOpt} Items/Page`">
                  </option>
                </select>
              </div>
              <div class="col">
              </div>
            </div>
          </div>
          <!-- page control -->
          <div class="col-12 col-md-6">
            <div class="d-flex justify-content-center justify-content-md-end mt-3 mt-md-2">
              <div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button class="btn btn-link" type="button"
                            :disabled="page === 1 || (suppliers && suppliers.length === 0)"
                            @click="prevPage">
                      <i class="bi bi-caret-left-fill"></i> Previous
                    </button>
                  </div>
                  <span class="input-group-text bg-white border-0"><small> {{ tableMetaInfo }} </small></span>
                  <div class="input-group-append">
                    <button class="btn btn-link" type="button"
                            :disabled="page === numberOfPages || (suppliers && suppliers.length === 0)" @click="nextPage">
                      Next <i class="bi bi-caret-right-fill"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </layout>
</template>

<script>
import layoutMixin from "@/mixins/layout.mixins";
import { formatMsDate } from "@/filters/date.filters";
import { stringHumanize } from "@/filters/string.filters";
import { hasObjectValue, clearObjectValue } from "@/helpers/common";
import ecSuppliersService from "@/services/ecsuppliers.services";
export default {

  name: "auth-supplier-search",
  components: {
  },
  mixins: [layoutMixin],
  computed: {
    authUser() {
      return this.$store.getters.authUser;
    },
  },
  watch: {
    $route:
        {
          handler() {
            this.setSearchParam(this.$route.query);
            this.searchSuppliers();
          },
          deep: true,
        }
  },
  filters: { formatMsDate, stringHumanize },
  data() {
    return {
      searchParams:
          {
            keyword: "",
          },
      responseMsg: {},
      suppliers: [],
      tableMetaInfo: "",
      isSearched: false,
      itemsPerPageOptions: [25, 50, 100],
      itemsPerPage: 25,
      page: 1,
      totalItemsLength: 0,
      numberOfPages: 0,
      numberOfElements: 0,
      listLoading: false,
    }
  },
  created() {
    this.setSearchParam(this.$route.query);
  },
  mounted() {
    this.searchSuppliers();
  },
  methods: {
    showResMsg(show, obj) {
      if (typeof obj === "string") {
        if (testJSON(obj)) { obj = JSON.parse(obj); }
        else obj = { error: true, message: obj };
      }
      this.responseMsg = obj;
      this.responseMsg.show = show;
    },
    setSearchParam(routeQuery) {
      if (hasObjectValue(routeQuery)) {
        let { page, size, ...query } = routeQuery;
        this.searchParams = query;

        // get table option form route
        if (page) this.page = parseInt(page);
        if (size) {
          if (this.itemsPerPageOptions.includes(parseInt(size))) {
            this.itemsPerPage = parseInt(size);
          }
          else { this.itemsPerPage = this.itemsPerPageOptions[0] || 25; }
        }

      }
    },
    async searchSuppliers() {
      try{
        this.isSearched = hasObjectValue(this.searchParams);

        this.$router
            .replace({
              name: "auth-supplier-search",
              params: { username: this.authUser.username },
              query: {
                ...this.searchParams,
                page: this.page,
                size: this.itemsPerPage
              },
            })
            .catch(() => { });
        await this.fetchSuppliers(this.searchParams, this.page, this.itemsPerPage);
      }catch (error) {
        console.error("Error while updating URL parameters:", error);
      }
    },
    async fetchSuppliers(searchParams, page, itemsPerPage) {
      this.listLoading = true;
      try {
        if (!this.searchParams.keyword) {
          this.showResMsg(true, { error: true, message: "Please insert a keyword to search." });
          this.cleanPage();
          return;
        }else{
          this.showResMsg(false, { error: false, message: "" });
        }

        const result = await ecSuppliersService.getECSuppliers(searchParams, page, itemsPerPage);

        if (!result.data.status) {
          this.showResMsg(true, { error: true, message: result.data.message });
          return;
        }

        this.suppliers = result.data.data.companies;
        this.totalItemsLength = result.data.totalElements;
        this.numberOfPages = result.data.totalPages;
        this.numberOfElements = result.data.numberofElements;

        this.getTableMetaInfo();

        if ((this.numberOfPages > 0 && this.page > this.numberOfPages) || (this.numberOfPages > 0 && this.page < 1)) {
          this.page = 1;
          await this.searchSuppliers();
        }
      } catch (err) {
        console.error("Error while getting ecplaza.net suppliers:", err);
      } finally {
        this.listLoading = false;
      }
    },
    clearSearch() {
      this.searchParams = clearObjectValue(this.searchParams);
      this.searchSuppliers();
    },
    cleanPage() {
      this.suppliers = [];
      this.page = 1;
      this.totalItemsLength = 0;
      this.numberOfPages = 0;
      this.numberOfElements = 0;
      this.getTableMetaInfo();
    },
    getTableMetaInfo() {
      let startItem = this.itemsPerPage * (this.page - 1) + 1,
          endItem = this.itemsPerPage * (this.page - 1) + this.numberOfElements;
      this.tableMetaInfo = `${startItem}-${endItem} of ${this.totalItemsLength}`;
    },
    nextPage() {
      this.page += 1;
      this.searchSuppliers();
    },
    prevPage() {
      this.page -= 1;
      this.searchSuppliers();
    },
  },
};
function testJSON(str) {
  try {
    JSON.parse(str); return true;
  }
  catch { return false; }
}
</script>

<style scoped>

.logo-container {
  margin-right: 10px;
}

.logo {
  border-radius: 50%; /* Make the logo image round */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  border: none;
}

.supplier-details {
  display: flex;
  flex-direction: column;
}

.border-line {
  border-bottom: 1px solid #ccc;
}
</style>
