import apiService from '../../services/api.services';
import jwtService from '../../services/jwt.services';
import { LOGIN, LOGOUT, FORCEAUTH, AUTOLOGIN } from '../action.types';
import { PURGE_AUTH, SET_AUTH, SET_ERROR } from '../mutations.types';

const state = { errors: null, user: jwtService.getAuthUser(), isAuthenticated: !!jwtService.getToken() };

const getters =
{
    authUser: state => state.user,
    isAuthenticated: state => state.isAuthenticated,
    errors: state => state.errors
};

const actions =
{
    [LOGIN]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiService.post('/login', payload).then(res => {
                commit(SET_AUTH, res.data);
                resolve(res.data && res.data.user);
            })
                .catch(({ response }) => {
                    console.log('Error:::', response.data.message || response.data);
                    commit(SET_ERROR, response.data);
                    reject(response.data);
                });
        });
    },
    [AUTOLOGIN]({ commit }, res) {
        commit(SET_AUTH, res.data);
    },
    [LOGOUT]({ commit }) {
        commit(PURGE_AUTH);
    },
    //add by Vlad to provide modern login functionality
    [FORCEAUTH]({ commit }, token) {
        commit(SET_AUTH, token);
    }
};

const mutations =
{
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, data) {
        state.isAuthenticated = true;
        state.user = data.user;
        state.errors = {};
        jwtService.saveAuthUser(data.user);
        jwtService.saveToken(data.token);
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        jwtService.destroy()
    }
};

export default { state, actions, mutations, getters };
