import { CHANGELANG } from '../action.types';
import { SET_LANG} from '../mutations.types';

const state = { errors: null, locale: null };

const getters =
    {
        currentLocale: state => state.locale,
    };

const actions =
    {
        [CHANGELANG]({ commit }, locale) {
            commit(SET_LANG, locale);
        },
    };


const mutations =
    {
        [SET_LANG](state, locale) {
            state.locale = locale;
        }
    };

export default { state, actions, mutations, getters};