import Vue from 'vue';
import VueRouter from 'vue-router';
import publicRoutes from './routes/routes';
import store from "../store";
import { AUTH_START_ROUTE } from "../helpers/authStartRoute";

Vue.use(VueRouter);

const getRouter = () => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: publicRoutes,
    scrollBehavior
  });
  router.beforeEach(routerGuard);

  return router
}

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) return savedPosition;
  if (to.hash) {
    return {
      selector: to.hash,
      behavior: 'smooth',
      // offset: { x: 0, y: 10 }
    }
  }
  return { x: 0, y: 0, behavior: 'smooth' };
}

const routerGuard = (to, from, next) => 
{
  // logged in
  const { authorize } = to.meta
  const AuthUser = store.getters.authUser
  // check requested route's authRequired status
  if (to.matched.some(record => record.meta.authRequired)) 
  {
    if (!store.getters.isAuthenticated) 
    {
      // not logged in, forward to signin page
      next({
        name: 'public-signin',
        query: { nextUrl: to.fullPath }
      });
    } 
    else 
    {
      if (authorize && authorize.length > 0 && !authorize.includes(AuthUser.role.roleName)) 
      { 
        // check
        // role not authorised
        next({ name: 'auth-forbidden' }) // redirect to forbidden page
      }
      else 
      {
        next() // has access
      }
    }
  }
  else 
    if (to.matched.some(record => record.name == 'public-signin')) 
    { // user enter signin
      if (store.getters.isAuthenticated) 
      {
        let routeName = "auth-forbidden";
        if (AUTH_START_ROUTE[AuthUser.role.roleName])
          routeName = AUTH_START_ROUTE[AuthUser.role.roleName];

        next({ name: routeName, params: { username: AuthUser.username } }) // redirect logged in user to home
      } 
      else 
      {
        next() // forward to signin
      }
    }
    else 
    {
      next() // no auth required, then forward requested route
    }
}

export default getRouter
