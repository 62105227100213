import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import jwtServices from './jwt.services';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    // Vue.axios.defaults.baseURL = "http://localhost:8080/";
    console.log("Vue.axios.defaults.baseURL",process.env.VUE_APP_API_URL);
    Vue.axios.defaults.headers.post['Content-Type'] = 'application/json';
    Vue.axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    // Vue.axios.defaults.headers.common[''] = '*';
    this.setResponseInterceptors();
  },

  setAuthorizationHeader() {
    Vue.axios.defaults.headers.common[
      'Authorization'
    ] = jwtServices.getToken() ? `Bearer ${jwtServices.getToken()}` : '';
  },

  setResponseInterceptors() {
    Vue.axios.interceptors.response.use(
      res => res,
      err => {
        if (err.response && (err.response.status == '400' || err.response.status == '401')) //extended by 401 error code by Vlad
        {
          jwtServices.destroy()
          window.location.href = process.env.BASE_URL;
        } else throw err;
      }
    );
  },

  get(resource, params, config) {
    this.setAuthorizationHeader();
    return Vue.axios.get(`${resource}`, { params, ...config });
  },

  post(resource, data, config) 
  {
    // console.log(data)
    this.setAuthorizationHeader();
    return Vue.axios.post(`${resource}`, data, config).then((res) => { return res; }); // modified by Vlad to control response if needed ( ignored for a while )
  },

  update(resource, data, config) {
    this.setAuthorizationHeader();
    return Vue.axios.put(`${resource}`, data, config);
  },

  delete(resource) {
    this.setAuthorizationHeader();
    return Vue.axios.delete(`${resource}`);
  }
};

export default ApiService;
