import { ROLE } from "./role";

// value is route name
export const AUTH_START_ROUTE = {
  [ROLE.Admin]: 'auth-user-list',
  [ROLE.BuyerAdmin]: 'auth-dashboard',
  [ROLE.BuyerUserAdmin]: 'auth-buyer-user-list',
  [ROLE.BuyerGeneralUser]: 'auth-purchase-project-list',
  [ROLE.SupplierAdmin]: 'auth-dashboard',
  [ROLE.SupplierUserAdmin]: 'auth-supplier-user-list',
  [ROLE.SupplierGeneralUser]: 'auth-quotation-request-list',
}
