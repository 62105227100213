var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-expand-md navbar-light bg-light elevation-2 border-bottom shadow-sm dash-nav header-top",
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("a", { staticClass: "navbar-brand" }, [
          _vm._v("Global Sourcing Platform"),
        ]),
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse navbar-right",
            attrs: { id: "navbarNav" },
          },
          [
            _c("ul", { staticClass: "navbar-nav ml-auto" }, [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link font-weight-bold",
                      attrs: { to: _vm.dashboard },
                    },
                    [_vm._v(" Dashboard ")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link font-weight-bold",
                      attrs: {
                        to: _vm.isBuyer()
                          ? _vm.supplierSearch
                          : _vm.buyersearch,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isBuyer() ? "Supplier Search" : "Buyer Search"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  { staticClass: "nav-link", attrs: { href: "#" } },
                  [_c("GoogleTranslateWidget")],
                  1
                ),
              ]),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link font-weight-bold",
                      attrs: { to: _vm.profileRoute },
                    },
                    [
                      _c("i", { staticClass: "bi bi-person-fill menu-icon" }),
                      _c("span", { staticClass: "notranslate" }, [
                        _vm._v(_vm._s(_vm.authUser.username)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle font-weight-bold",
                    attrs: {
                      href: "#",
                      id: "navbarDropdownMenuLink",
                      role: "button",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _c("i", { staticClass: "bi bi-justify menu-icon" }),
                    _vm._v(" " + _vm._s(_vm.$t("menu")) + " "),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu",
                    attrs: { "aria-labelledby": "navbarDropdownMenuLink" },
                  },
                  [
                    _vm._l(_vm.menuList, function (menu, idx) {
                      return [
                        menu.header
                          ? _c("h6", {
                              key: `menu-item-header-${idx}`,
                              staticClass: "dropdown-header",
                              domProps: { textContent: _vm._s(menu.label) },
                            })
                          : menu.divider
                          ? _c("div", {
                              key: `menu-item-divider-${idx}`,
                              staticClass: "dropdown-divider",
                            })
                          : menu.subMenu
                          ? _c(
                              "div",
                              {
                                key: `menu-item-${idx}`,
                                staticClass: "dropdown-item",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.openSubMenu(idx)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.closeSubMenu()
                                  },
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    key: `menu-item-${idx}`,
                                    staticClass: "main-menu-style",
                                    attrs: { to: menu.to || null },
                                  },
                                  [
                                    _c("i", { class: menu.icon }),
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          notranslate: _vm.noTranslateMenu(
                                            menu.label
                                          ),
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t(menu.label)))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isSubMenuOpen(idx),
                                        expression: "isSubMenuOpen(idx)",
                                      },
                                    ],
                                    staticClass: "sub-menu",
                                    on: {
                                      mouseover: function ($event) {
                                        return _vm.keepSubMenuOpen(idx)
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.closeSubMenu(idx)
                                      },
                                    },
                                  },
                                  _vm._l(
                                    menu.subMenu,
                                    function (subMenuItem, subIdx) {
                                      return _c(
                                        "router-link",
                                        {
                                          key: `sub-menu-item-link-${subIdx}`,
                                          staticClass: "dropdown-item",
                                          attrs: { to: subMenuItem.to },
                                        },
                                        [
                                          _c("i", { class: subMenuItem.icon }),
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                notranslate:
                                                  _vm.noTranslateMenu(
                                                    subMenuItem.label
                                                  ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(subMenuItem.label)
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "router-link",
                              {
                                key: `menu-item-${idx}`,
                                staticClass: "dropdown-item",
                                attrs: { to: menu.to || null },
                              },
                              [
                                _c("i", { class: menu.icon }),
                                _c(
                                  "span",
                                  {
                                    class: {
                                      notranslate: _vm.noTranslateMenu(
                                        menu.label
                                      ),
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t(menu.label)))]
                                ),
                              ]
                            ),
                      ]
                    }),
                    _c("div", { staticClass: "dropdown-divider" }),
                    _c("h6", { staticClass: "dropdown-header" }, [
                      _vm._v(_vm._s(_vm.$t("action")) + " "),
                    ]),
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$store.getters.authUser.method !== "NEW",
                            expression:
                              "$store.getters.authUser.method !== 'NEW'",
                          },
                        ],
                        staticClass: "dropdown-item",
                        attrs: {
                          to: {
                            name: "auth-user-change-password",
                            params: { username: _vm.authUser.username },
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bi bi-lock" }),
                        _vm._v(" " + _vm._s(_vm.$t("changePassword")) + " "),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: { click: _vm.logout },
                      },
                      [
                        _c("i", { staticClass: "bi-box-arrow-right pd4" }),
                        _c("span", { staticClass: "pd4" }, [
                          _vm._v(_vm._s(_vm.$t("logout"))),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
              _c("li", {
                staticClass: "nav-item",
                staticStyle: { width: "100px" },
              }),
              _c("li", {
                staticClass: "nav-item",
                staticStyle: { width: "100px" },
              }),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarNav",
          "aria-controls": "navbarNav",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }