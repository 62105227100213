const ACCESS_TOKEN = process.env.VUE_APP_AUTH_PREFIX + '_token';
const AUTH_USER = process.env.VUE_APP_AUTH_PREFIX + '_user';

export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN);
};

export const saveToken = token => {
  window.localStorage.setItem(ACCESS_TOKEN, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
};

export const getAuthUser = () => {
  let authUser = window.localStorage.getItem(AUTH_USER);
  if (authUser) return JSON.parse(authUser)
  else return {}
};

export const saveAuthUser = user => {
  if (user)
    window.localStorage.setItem(AUTH_USER, JSON.stringify(user));
};

export const destroyAuthUser = () => {
  window.localStorage.removeItem(AUTH_USER);
};

export const destroy = () => {
  destroyToken()
  destroyAuthUser()
};


export default {
  getToken,
  saveToken,
  destroyToken,
  getAuthUser,
  saveAuthUser,
  destroyAuthUser,
  destroy
};
