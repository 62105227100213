var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout", { attrs: { template: _vm.$MixinLayout } }, [
    _c("div", { staticClass: "container" }, [
      _c("h3", { staticClass: "text-center my-4" }, [
        _vm._v("Supplier Search"),
      ]),
      _c("div", { staticClass: "card bg-light mb-4" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.searchSuppliers.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "card-body d-none d-md-block" }, [
              _c("div", { staticClass: "input-group form-row" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchParams.keyword,
                      expression: "searchParams.keyword",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "Type a Keyword to Search",
                  },
                  domProps: { value: _vm.searchParams.keyword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchParams, "keyword", $event.target.value)
                    },
                  },
                }),
                _vm.isSearched
                  ? _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.clearSearch()
                            },
                          },
                        },
                        [_c("i", { staticClass: "bi bi-x" }), _vm._v(" Clear ")]
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", { staticClass: "bi bi-search" }),
                      _vm._v(" Search "),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _vm.responseMsg.show
        ? _c(
            "div",
            {
              staticClass: "alert fade",
              class: {
                show: _vm.responseMsg && _vm.responseMsg.show,
                "alert-danger": _vm.responseMsg && _vm.responseMsg.error,
                "alert-success": _vm.responseMsg && !_vm.responseMsg.error,
              },
              attrs: { role: "alert" },
            },
            [
              _vm._v(_vm._s(_vm.responseMsg.message || "!") + " "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: {
                    click: function ($event) {
                      _vm.responseMsg = { show: false }
                    },
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table table-striped" }, [
          _c("caption", [
            _vm._v(" Supplier Search "),
            _c("strong", [
              _vm._v(
                "company name, product name, category, country,contact name, phone, address"
              ),
            ]),
          ]),
          _c("colgroup", [
            _c("col", { staticStyle: { width: "20%", "min-width": "120px" } }),
            _c("col", { staticStyle: { width: "20%", "min-width": "100px" } }),
            _c("col", { staticStyle: { width: "25%", "min-width": "150px" } }),
            _c("col", { staticStyle: { width: "*%", "min-width": "100px" } }),
            _c("col", { staticStyle: { width: "15%", "min-width": "100px" } }),
            _c("col", { staticStyle: { width: "15%", "min-width": "100px" } }),
            _c("col", { staticStyle: { width: "20%", "min-width": "200px" } }),
          ]),
          _c("thead", { staticClass: "thead-skyblue" }, [
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Company Name")]),
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Product Name")]),
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Main Items")]),
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Country")]),
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Contact Name")]),
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Phone")]),
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Address")]),
            ]),
          ]),
          _c(
            "tbody",
            [
              _vm.listLoading
                ? [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("div", { staticClass: "text-center" }, [
                          _c(
                            "div",
                            {
                              staticClass: "spinner-border spinner-border-sm",
                              attrs: { role: "status" },
                            },
                            [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Loading..."),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                : !_vm.listLoading && _vm.suppliers.length === 0
                ? [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v("No data"),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._l(_vm.suppliers, function (supplier) {
                    return _c(
                      "tr",
                      {
                        key: `table-item-${supplier.id}`,
                        staticClass: "border-line",
                      },
                      [
                        _c(
                          "td",
                          { staticClass: "pt-3 notranslate text-center" },
                          [
                            _c("div", { staticClass: "logo-container" }, [
                              supplier.logoImage
                                ? _c("img", {
                                    staticClass: "img-fluid logo",
                                    staticStyle: {
                                      "max-height": "50px",
                                      "max-width": "50px",
                                    },
                                    attrs: {
                                      src: supplier.logoImage,
                                      alt: "Supplier Logo",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "supplier-details" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      supplier.minisite || "javascript:void(0)",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(supplier.name))]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "td",
                          { staticClass: "pt-3 notranslate text-center" },
                          [
                            _vm._v(
                              _vm._s(
                                supplier.product ? supplier.product.name : ""
                              )
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          { staticClass: "pt-3 notranslate text-center" },
                          [_vm._v(_vm._s(supplier.mainItem))]
                        ),
                        _c(
                          "td",
                          { staticClass: "pt-3 notranslate text-center" },
                          [_vm._v(_vm._s(supplier.countryName))]
                        ),
                        _c(
                          "td",
                          { staticClass: "pt-3 notranslate text-center" },
                          [_vm._v(_vm._s(supplier.contact))]
                        ),
                        _c(
                          "td",
                          { staticClass: "pt-3 notranslate text-center" },
                          [_vm._v(_vm._s(supplier.phone))]
                        ),
                        _c(
                          "td",
                          { staticClass: "pt-3 notranslate text-center" },
                          [_vm._v(_vm._s(supplier.address))]
                        ),
                      ]
                    )
                  }),
            ],
            2
          ),
        ]),
      ]),
      _c("div", { staticClass: "container p-0" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "form-row mt-3 mt-md-2" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.itemsPerPage,
                        expression: "itemsPerPage",
                      },
                    ],
                    staticClass: "form-control form-control-sm",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.itemsPerPage = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.searchSuppliers,
                      ],
                    },
                  },
                  _vm._l(_vm.itemsPerPageOptions, function (ippOpt, idx) {
                    return _c("option", {
                      key: `ippOpt-selectitem-${idx}`,
                      domProps: {
                        value: ippOpt,
                        textContent: _vm._s(`${ippOpt} Items/Page`),
                      },
                    })
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "col" }),
            ]),
          ]),
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center justify-content-md-end mt-3 mt-md-2",
              },
              [
                _c("div", [
                  _c("div", { staticClass: "input-group" }, [
                    _c("div", { staticClass: "input-group-prepend" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-link",
                          attrs: {
                            type: "button",
                            disabled:
                              _vm.page === 1 ||
                              (_vm.suppliers && _vm.suppliers.length === 0),
                          },
                          on: { click: _vm.prevPage },
                        },
                        [
                          _c("i", { staticClass: "bi bi-caret-left-fill" }),
                          _vm._v(" Previous "),
                        ]
                      ),
                    ]),
                    _c(
                      "span",
                      { staticClass: "input-group-text bg-white border-0" },
                      [
                        _c("small", [
                          _vm._v(" " + _vm._s(_vm.tableMetaInfo) + " "),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-link",
                          attrs: {
                            type: "button",
                            disabled:
                              _vm.page === _vm.numberOfPages ||
                              (_vm.suppliers && _vm.suppliers.length === 0),
                          },
                          on: { click: _vm.nextPage },
                        },
                        [
                          _vm._v(" Next "),
                          _c("i", { staticClass: "bi bi-caret-right-fill" }),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }