import apiService from "./api.services";

const dashboardService = {
  getDashboard(userId) {
    let params = { userId }
    return apiService.get('/auth/dashboard', params)
  }
}

export default dashboardService;
