<template>
  <div class="lang-icon">
    <i class="fas fa-globe globe-icon"></i>
    <div class="hd_lang">
      <div id="google_translate_element"></div>
    </div>
  </div>
</template>

<script>
export default {
  beforeMount() {
    this.loadGoogleTranslateAPI();
  },
  methods: {
    loadGoogleTranslateAPI() {
      const script = document.createElement('script');
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      document.head.appendChild(script);
    },
  },
};
</script>
<style>
.globe-icon {
  display: inline-block;
  cursor: pointer;
  margin: 5px;
  font-size: 16px;
  top: 3px;
  position: relative;
}
.globe-icon i {
  vertical-align: middle;
}
/* Hide the Google Translate attribution */
#google_translate_element span {
  display: none !important;
}

#google_translate_element .goog-te-combo{
  border: 1px solid #007bff2b;
}
#google_translate_element .goog-te-gadget {
  font-size: 0px;
}
.goog-te-gadget .goog-te-combo {
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.71);
  width: 100px;
  background-color: #ffff !important;
}
.goog-te-gadget .goog-te-combo:focus {
  background-color: transparent;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
  display: none !important;
  position: unset;
}
#goog-gt-vt{
  display: none;
}
/* hide banner*/
body {
  top: 0px !important;
}
body > .skiptranslate > iframe.skiptranslate {
  display: none !important;
  visibility: hidden !important;
}
/*end hide banner*/
</style>

