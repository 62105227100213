
export default {
  hello: 'Hello',

  // Buyer Sign up
  generalInfo: "General Info",
  buyerSignUp: "Buyer Sign Up",
  userId: "User Id",
  representativeName: "Representative Name",
  password: "Password",
  contactPerson: "Contact Person",
  companyName: "Company Name",
  contactPersonPhNo: "Contact Person Phone Number",
  companyRegNo: "Company Registration No",
  contactEmail: "Contact Email",
  callNo: "Call Number",
  acceptTermsConditions: "Accept Terms & Conditions",
  join: "Join",

  //footer
  gsp: "Global Sourcing Platform",
  address: "ECPlaza | Address: 143-40, Gangdong-daero, 1st floor, Gangdong-gu, Seoul, Republic of Korea | CEO: In-Kyu Park | Personal Information Manager: Dong-Ha Lee | Business License No.: 212-81-46495 | Online Marketing Business Report No.: 833 ",
  copyright: "Copyright © 2023 ECPlaza Network Inc. All rights reserved. ",

  carouselDesc0: "ECPlaza provides a cooperative service by developing a 'Global Sourcing Platform.' \n This platform aims to facilitate connections between major buyers and both domestic and foreign companies.",
  carouselDesc1: "Introducing a targeted product, managing proposals, updating and organizing data, and enhancing business awareness.",

  //signUp page
  signUp: "Sign Up",
  supplierSignUp : "Supplier Sign Up",
  changeRegMethod: "Change registration method",
  usingModernReg: "Using modern registration",
  usingGeneralReg: "Using general registration",
  welcomeText: "Welcome to join us!",
  thankText: " Thank you for using the global sourcing platform services and products (hereinafter'services'). These terms and conditions describe the relationship between EC Plaza Co., Ltd. (hereinafter referred to as'EC Plaza'), which provides services in connection with the use of various sourcing platform services, and a sourcing platform service member (hereinafter referred to as'member') or non-member using the same. It contains useful information that can help you use the sourcing platform service.",
  thankText2: "If you use the sourcing platform service or sign up as a sourcing platform service member, you will confirm or agree to these terms and conditions and related operating policies, so please take a moment to review them carefully.",
  continue: "Continue",
  modernReg: "Modern Registration",
  generalReg: "General Registration",

  //supplier sign up
  mainTradingItem: "Main Trading Item ",
  faxNo:  "Fax Number ",
  category: "Category",
  nation: "Nation",
  companyType: "Company Type",
  homePageUrl: "Home Page Url",
  supplierAddress: "Address",
  representativePhNo: "Representative Phone Number",

  //buyer user
  invitation: "Supplier Invitation",
  buyerUser: "Buyer User",
  register: "Register",
  name: "Name",
  department: "Department",
  position: "Position",
  officeNo: "Office Number",
  mobileNo: "Mobile Number",
  registrationDate: "Registration Date",
  action: "Action",
  edit: "Edit",
  search: "Search",
  clear: "Clear",
  
  //menu
  menu: "Menu",
  supplierEntry: "Supplier Entry",
  purchaseProject: "Purchase Project",
  reqForQuotation: "Quotation Request",
  quotation: "Quotation",
  quotationHistory: "Quotation History",
  purchaseOrder: "Purchase Order",
  purchaseOrderHistory: "Purchase Order History",
  deliInspection: "Delivery Inspection",
  deliInspectionHistory: "Delivery Inspection History",
  usance: "Usance",
  changePassword: "Change Password",
  logout: "Logout",



};